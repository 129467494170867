export const getPiste = (critere, score) => {
    switch (critere) {
        case "Environnement":
            if (score === 5) {
                return ("Votre bâtiment ou votre projet de construction semble bien optimisé. Votre projet est traité de manière à prendre en compte la majorité des thématiques liées à l'envrironnement.\n" +
                    "\n" +
                    "Néanmoins, un travail d'évaluation de votre projet prenant en compte ses atouts/contraintes environnementales permettrait de mettre en lumière des pistes d'optimisation qui pourraient vous aider à augmenter la qualité environnementale de votre projet et de vous démarquer par rapport à d'autres projets similaires sur ce thème. ");
            } else if (score === 4) {
                return ("Votre bâtiment ou votre projet de construction semble bien optimisé. Votre projet est traité de manière à prendre en compte la majorité des thématiques liées à l'envrironnement.\n" +
                    "\n" +
                    "Néanmoins, un travail d'évaluation de votre projet prenant en compte ses atouts/contraintes environnementales permettrait de mettre en lumière des pistes d'optimisation qui pourraient vous aider à augmenter la qualité environnementale de votre projet et de vous démarquer par rapport à d'autres projets similaires sur ce thème. ");
            } else if (score === 3) {
                return ("Votre bâtiment ou votre projet de construction semble en cohérence avec vos besoins. Votre projet est traité de manière à prendre en compte la majorité des  thématiques liées à l'envrironnement.\n" +
                    "\n" +
                    "Néanmoins, un travail d'évaluation de votre projet prenant en compte ses atouts/contraintes environnementales permettrait de mettre en lumière des pistes d'optimisation qui pourraient vous aider à augmenter la qualité environnementale de votre projet et de vous démarquer par rapport à d'autres projets similaires sur ce thème.");
            } else if (score === 2) {
                return ("Votre bâtiment ou votre projet de construction semble peu optimisé ou ne traite que d'une seule thématique environnementale.\n" +
                    "\n" +
                    "Une évaluation de votre projet prenant en compte ses atouts/contraintes environnementales permettrait d'amener des propositions d'amélioration de la qualité environenemntale de votre projet. ");
            } else if (score === 1) {
                return ("Votre bâtiment ou votre projet de construction semble très peu optimisé ou ne traite que d'une seule thématique environnementale.\n" +
                    "\n" +
                    "Une évaluation de votre projet prenant en compte ses atouts/contraintes environnementales permettrait d'amener des propositions d'amélioration de la qualité environenemntale de votre projet. ");
            }
            break;
        case "Connectivité":
            if (score === 5) {
                return ("Votre bâtiment ou votre projet de construction semble optimisé dans le respect des standards contemporains.  Votre projet possède une architecture technique et un management de projet intelligent. L'evolutivté technique et l'interopéraibilité avec d'autres systèmes seront les points fort de votre projet. \n" +
                    "\n" +
                    "Si votre projet est en phase de faisabilité, un programme précIs, en Cohérence avec vos attentes, vous permettra de vous assurer de l'adéquation des phases de conceptions et de réalisation avec ces bons résultats.  ");
            } else if (score === 4) {
                return ("Votre bâtiment ou votre projet de construction semble bien optimisé. L'architecture technique de votre projet est ouvert et intéropérable. Votre projet pourra évoluer dans le temps vers le bâtiment intelligent.  \n" +
                    "\n" +
                    "Si votre projet est en phase de faisabilité, un programme précis, en cohérence avec vos attentes, vous permettra de vous assurer de l'adéquation des phases de conceptions et de réalisation avec ces bons résultats.");
            } else if (score === 3) {
                return ("Votre bâtiment ou votre projet de construction semble en cohérence avec vos besoins.  Votre projet est pensée pour échanger des données et offrir une bonne connectivité télécoms pour les usagers. \n" +
                    "\n" +
                    "\n" +
                    "Néanmoins un travail d'audit fonctionnel de votre projet ou de vos locaux permettraient de mettre en lumière des pistes d'optimisation qui pourraient vous aider à améliorer l'évolutivité des vos installations.");
            } else if (score === 2) {
                return ("Votre bâtiment ou votre projet de construction semble peu optimisé. L'ouverture de votre installation semble  insuffisante. Il est possible que votre couverture WIFI/GSM soit insuffisante pour offrir une bonne communication.\n" +
                    "\n" +
                    "Un audit de connectivité de votre projet permettrait de souligner les élèments qui pourraient être améliorés afin de limiter le nombre d'invesstissement nécessaire à l'évolutivité technique de votre projet. ");
            } else if (score === 1) {
                return ("Votre Bâtiment ou votre projet de construction semble très peu optimisé.\n" +
                    "L'ouverture de votre installation semble très insuffisante. Il risque d'être compliqué de faire communiquer différents systèmes dans le temps sans passer par des travaux lourd financièrement.\n" +
                    "\n" +
                    "Un audit de connectivité de votre projet permettrait de souligner les élèments qui pourraient être améliorés afin de limiter  le nombre d'investissement nécessaire à l'évolutivité technique de votre projet.");
            }
            break;
        case "BIM":
            if (score === 5) {
                return ("La démarche BIM sur votre projet ou votre bâtiment pourra facilement se mettre en place. Les objectifs choisis sont ambitieux mais nous pouvons vous en proposer encore d'avantage.\n" +
                    "Les intervenants BIM devront produirent des maquettes numériques de qualité afin de respecter vos objectifs. Nous vous proposons de vos accompagner sur votre projet afin de vérifier et faire appliquer les documents cadres BIM du projet afin de vérifier que les objectifs sont atteints.");
            } else if (score === 4) {
                return ("Votre projet ou votre bâtiment est fait pour entreprendre une démarche BIM.\n" +
                    "D'autres objectifs BIM que ceux choisis peuvent également être définis pour votre projet. Les exigences BIM devront être contrôlés afin d'assurer leur respect par les intervenants. Nous restons à votre disposition pour vous conseiller et vous accompagner.");
            } else if (score === 3) {
                return ("La mise en place du BIM sur projet peut s'effectuer sur votre projet de manière normalement.\n" +
                    "Les intervenants au projets devront respecter les règles définies dans les documents cadres produits par le BIM Manager pour respecter les objectifs BIM.\n" +
                    "Nous restons à votre disposition pour effectuer cette mission sur votre projet.");
            } else if (score === 2) {
                return ("Votre bâtiment ou votre projet de construction semble propice à la mise en place du BIM.\n" +
                    "Cela ne signifie pas que votre projet n'est pas compatbile avec la démarche mais que ça mise en place devra demander une certaines rigueur aux intervenants.\n" +
                    "\n" +
                    "Il se peut également que les objectifs BIM pour votre projet soient relevés pour correspondre à vos besoins. Nous restons à votre disposition pour vous conseiller sur votre projet.");
            } else if (score === 1) {
                return ("Votre bâtiment ou votre projet de construction semble difficilement propice à la mise en place du BIM.\n" +
                    "Cela ne signifie pas que votre projet n'est pas compatbile avec la démarche mais que ça mise en place devra demander des efforts supplémentaires aux intervenants.\n" +
                    "\n" +
                    "Il se peut également que les objectifs BIM pour votre projet ne soient pas correctement identifiés.\n" +
                    "Nous restons à votre disposition pour vous conseiller sur votre projet.");
            }
            break;
        case "Fonctionnel":
            if (score === 5) {
                return ("Votre bâtiment ou votre projet de construction semble optimisé dans le respect des standards contemporains. Les surfaces apparaissent en très bonne adéquation avec les activités projetées et peu d'espaces sont sous utilisés. Si votre projet est en phase de faisabilité, un programme précis, en cohérence avec vos attentes, vous permettra de vous assurer de l'adéquation des phases de conceptions et de réalisation avec ces bons résultats.");
            } else if (score === 4) {
                return ("Votre bâtiment ou votre projet de construction semble bien optimisé. Les surfaces apparaissent en adéquation avec les activités projetées et peu d'espaces semblent sous utilisés. Si votre projet est en phase de faisabilité, un programme précis, en cohérence avec vos attentes, vous permettra de vous assurer de l'adéquation des phases de conceptions et de réalisation avec ces bons résultats.");
            } else if (score === 3) {
                return ("Votre bâtiment ou votre projet de construction semble en cohérence avec vos besoins. Les surfaces apparaissent en adéquation avec les activités projetées et peu d'espaces semblent sous utilisés. Néanmoins un travail d'audit fonctionnel de votre projet ou de vos locaux permettraient de mettre en lumière des pistes d'optimisation qui pourraient vous aider à rationnaliser les espaces dont vous disposez.");
            } else if (score === 2) {
                return ("Votre bâtiment ou votre projet de construction semble peu optimisé. Il est possible que certaines surfaces apparaissent comme insuffisantes au regard des activités projetées ou qu'à l'inverse, des espaces soient sous utilisés. Un audit fonctionnel de votre projet permettrait de souligner les élèments qui pourraient être améliorés afin de limiter la consomation d'espace et de ressources inutiles.");
            } else if (score === 1) {
                return ("Votre bâtiment ou votre projet de construction semble très peu optimisé. Il est possible que certaines surfaces apparaissent comme très insuffisantes au regard des activités projetées ou qu'à l'inverse, des espaces soient fortement sous utilisés. Un audit fonctionnel de votre projet permettrait de souligner les élèments qui pourraient être améliorés afin de limiter la consomation d'espace et de ressources inutiles.");
            }
            break;
    }
};