import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import style from './homeBatimentItem_styles';
import LogoProjexBlanc from "../../images/LOGO_GP_BLANC.png";
/*
import Logo2s from "../../../public/LABEL 2 ETOILE.png";
import Logo3s from "../../../public/LABEL 3 ETOILE.png";
import Logo4s from "../../../public/LABEL 4 ETOILE.png";
import Logo5s from "../../../public/LABEL 5 ETOILE.png";*/
import Logo1s from '../../images/LABEL 1 ETOILE_512.png';
import Logo2s from '../../images/LABEL 2 ETOILES_512.png';
import Logo3s from '../../images/LABEL 3 ETOILES_512.png';
import Logo4s from '../../images/LABEL 4 ETOILES_512.png';
import Logo5s from '../../images/LABEL 4 ETOILES_512.png';

/*import Logo1s from '../../images/LABEL 1 ETOILE.png';
import Logo2s from '../../images/LABEL 2 ETOILES.png';
import Logo3s from '../../images/LABEL 3 ETOILES.png';
import Logo4s from '../../images/LABEL 4 ETOILES.png';
import Logo5s from '../../images/LABEL 5 ETOILES.png';*/

import DeleteIcon from '@material-ui/icons/Delete';
import Loading from "../loading/loading";
import {openNotification} from "../../actions/notifications";

const useStyles = createUseStyles(style);

const HomeBatimentItem = (props) => {
    const classes = useStyles();
    const [logo, setlogo] = useState();
    useEffect(() => {
        switch (props.bat.Icon) {
            case 1:
                setlogo(Logo1s);
                break;
            case 2:
                setlogo(Logo2s);
                break;
            case 3:
                setlogo(Logo3s);
                break;
            case 4:
                setlogo(Logo4s);
                break;
            case 5:
                setlogo(Logo5s);
                break;
            default:
                setlogo(Logo1s);
        }
    }, []);

    const deleteFolder = () => {
        fetch(`${process.env.REACT_APP_API_URL}/dossiers/${props.bat._id}`, {
            method: 'DELETE',
            credentials: "include",
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        }).then(res => {
            if (res.status === 200) {
                props.refresh();
                openNotification({
                    type: 'success',
                    message: "Le dossier à bien été supprimé"
                });
            } else {
                openNotification({
                    type: 'error',
                    message: "Une erreur s'est produite"
                });
            }
        })
    };

    if (logo) {
        return (
            <div className={classes.MainContainer}>
                <div className={classes.containerItem}>
                    {/*<div onClick={props.click}>*/}
                        <div className={classes.textContainer} onClick={props.click}>
                            <div className={classes.batimentTitle}>{props.bat.Nom}</div>
                            <div className={classes.batimentDescription}>{props.bat.Adresse}</div>
                        </div>
                        <img src={logo} className={classes.logo}/>
                    {/*</div>*/}
                    <DeleteIcon className={classes.DeleteIcon} onClick={() => deleteFolder()} style={{fontSize: 30}}/>
                </div>
            </div>
        )
    } else {
        return (<Loading/>);
    }
};

export default HomeBatimentItem;
