import {  Button, notification } from 'antd';
import React from "react";
import {sendActivateMail} from "./auth";

export const openNotification = (options = {
    type: 'open',
    duration: 3,
    message: 'Notification',
    onClick: () => null
}) => {
    const {type, message, email, duration, description, onClick} = options;
    const btn = (
        <Button type="primary" size="small" onClick={() => sendActivateMail(email)}>
            Envoyer
        </Button>
    );
    if (message === "Compte non activé\n") {
        notification[type]({
            message: "Compte non activé",
            description: "Envoyer le mail d'activation ?",
            onClick,
            duration,
            btn
        });
    } else {
        notification[type]({
            message: message,
            description,
            onClick,
            duration
        });
    }
};