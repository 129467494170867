export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        color: 'white',
        margin: 'auto',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        animation: '15s ease 0s infinite $backgroundAnime'
    },
    '@keyframes backgroundAnime': {
        '0%': {
            backgroundPosition: '0% 50%',
        },
        '50%': {
            backgroundPosition: '100% 50%',
        },
        '100%': {
            backgroundPosition: '0% 50%',
        }
    },
    subContainer: {
        width: "100%",
        margin: 'auto'
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    starsTitle: {
        width: 'fit-content',
        margin: 'auto'
    },
    backIcon: {
        color: 'white',
        marginLeft: 5
    },
    logo: {
        width: 100,
        height: 100,
        marginLeft: 30,
        marginRight: 30,
        marginTop: 30,
        backgroundColor: 'white',
        borderRadius: 50
    },
    headerTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 70,
        textAlign: 'left',
        width: '100%'
    },
    textLogoContainer: {
        display: 'flex'
    },
    batimentName: {
        fontWeight: 'bold',
        fontSize: 20
    },
    whiteContainer: {
        backgroundColor: 'white',
        borderRadius: 15,
        marginTop: 70,
        marginBottom: 35,
        overflow: 'auto',
        marginRight: 10,
        marginLeft: 10,
        padding: 10
    },
    critereRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 15
    },
    critereTitle: {
        fontWeight: 'bold',
        fontSize: 20,
        color: 'black',
        marginLeft: 15
    },
    logoBottom: {
        width: 200,
        bottom: 0,
        marginBottom: 20
    },
    logoContainer: {
        width: '100%',
        margin: 'auto',
        bottom: 0
    },
    '@media (min-width: 800px)': {
        subContainer: {
            width: '70%'
        },
        logoContainer: {
            width: '70%'
        }
    },
    '@media (min-width: 1200px)': {
        subContainer: {
            width: '50%'
        },
        logoContainer: {
            width: '50%'
        }
    },
    '@media (max-height: 900px)': {
        logoContainer: {
            position: 'relative'
        }
    }
}
