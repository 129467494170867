import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from 'react-jss';
import style from './ResultRse_styles';
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Chart from "react-apexcharts"; 
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from '@material-ui/icons/Home';
import {
    RseLess50,
    RseSupp50,
    RseSupp80,
} from '../../data/data';

const useStyles = createUseStyles(style);

const ResultRse = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector(store => store.user);
    const [resultData, setResultData] = useState();
    const [score, setscore] = useState(0);
    const [series, setSeries] = useState();
    const [options, setOptions] = useState();
    const [Conclusion, setConclusion] = useState();
    const [Redirect, setRedirect] = useState(false);

    const captions = {
        theme1: 'Gouvernance',
        theme2: 'Droit de l’homme et conditions de travail',
        theme3: 'Environnement',
        theme4: 'Communauté et développement local',
        theme5: 'Loyauté des pratiques'
    };

    const calculateScore = (scoreString) => {
        let returing = 0;
        for (let i = 0; i < scoreString.length; i++) {
            returing += parseInt(scoreString[i]);
        }
        return (returing);
    }

    function ReplaceConclusionVariables(conclusion, scoresTab, totalScore){
        return conclusion.replaceAll("VAR_FIRSTSCORE", scoresTab[0].theme)
        .replaceAll("VAR_SECONDSCORE", scoresTab[1].theme)
        .replaceAll("VAR_THIRDSCORE", scoresTab[2].theme)
        .replaceAll("VAR_FOURTHSCORE", scoresTab[3].theme)
        .replaceAll("VAR_FIFTHSCORE", scoresTab[4].theme)
        .replaceAll("VAR_SCORE", totalScore);
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getuser`, { withCredentials: true })
            .then(result => {
                setscore(result.data.scoreRse);
                axios.get(`${process.env.REACT_APP_API_URL}/rse/${localStorage.getItem('userId')}`, { withCredentials: true })
                    .then(res => {
                        setResultData(res);
                        let s1 = calculateScore(res.data.theme1);
                        let s2 = calculateScore(res.data.theme2);
                        let s3 = calculateScore(res.data.theme3);
                        let s4 = calculateScore(res.data.theme4);
                        let s5 = calculateScore(res.data.theme5);
                        setSeries([{
                            name: 'Score RSE',
                            data: [s1, s2, s3, s4, s5],
                        }]);

                        setOptions({
                            chart: {
                                height: 350,
                                type: 'radar',
                            },
                            xaxis: {
                                categories: [
                                    'Gouvernance',
                                    'Droit de l’homme et conditions de travail',
                                    'Environnement',
                                    'Communauté et développement local',
                                    'Loyauté des pratiques'
                                ]
                            },
                            yaxis: {
                                max: 20,
                                min: 0,
                                tickAmount: 4
                            },
                            responsive: [{
                                breakpoint: 750,
                                options: {
                                    chart: {
                                        height: 500,
                                        type: 'radar',
                                    },
                                    xaxis: {
                                        categories: [
                                            'GOUV',
                                            'DDH & CDT',
                                            'ENV',
                                            'C & DL',
                                            'LDP'
                                        ],
                                        labels: {
                                            show: true,
                                            style: {
                                                colors: ["#a8a8a8"],
                                                fontSize: "10px",
                                                fontFamily: 'Arial'
                                            }
                                        }
                                    }
                                },
                            }]
                        });
                        //conclusion :
                        let sortedResults = [
                            {theme: "Gouvernance", score:s1},
                            {theme: "Droit de l’homme et conditions de travail", score:s2},
                            {theme: "Environnement", score:s3},
                            {theme: "Communauté et développement local", score:s4},
                            {theme: "Loyauté des pratiques", score:s5}
                        ];
                        sortedResults.sort((a,b) => { return b.score - a.score});
                        if (result.data.scoreRse > 80) {
                            setConclusion(ReplaceConclusionVariables(RseSupp80, sortedResults, result.data.scoreRse));
                        } else if (result.data.scoreRse > 50) {
                            setConclusion(ReplaceConclusionVariables(RseSupp50, sortedResults, result.data.scoreRse));
                        } else {
                            setConclusion(ReplaceConclusionVariables(RseLess50, sortedResults, result.data.scoreRse));
                        }
                    });
            });
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <div className={classes.headerContainer}>
                    <Link to={"/home"}>
                        <IconButton style={{
                            backgroundColor: '#99A0AB',
                            height: 35,
                            width: 35,
                            left: 15,
                            top: 15
                        }}>
                            <HomeIcon className={classes.backIcon} />
                        </IconButton>
                    </Link>
                </div>
                <div className={classes.formContainer}>
                    <h1 className={classes.Title}>Votre score RSE</h1>
                    {score && <div className={classes.scoreTitle}>{score}%</div>}
                    {series && options &&
                        <Chart options={options} series={series} type="radar" style={{ color: 'black' }} height={600} />}
                    {/*resultData && <div className={classes.ContainerThemes}>
                        <div className={classes.subContainerThemes}>
                            <img alt={"gouvernance"} src={LogoPlus} className={classes.logoTheme}/>
                            <span className={classes.themeTitleInList}>Gouvernance {calculateScore(resultData.data.theme1)}</span>
                        </div>
                        <div className={classes.subContainerThemes}>
                            <img alt={"gouvernance"} src={LogoPlus} className={classes.logoTheme}/>
                            <span className={classes.themeTitleInList}>Gouvernance {calculateScore(resultData.data.theme1)}</span>
                        </div>
                        <div className={classes.subContainerThemes}>
                            <img alt={"gouvernance"} src={LogoPlus} className={classes.logoTheme}/>
                            <span className={classes.themeTitleInList}>Gouvernance {calculateScore(resultData.data.theme1)}</span>
                        </div>
                        <div className={classes.subContainerThemes}>
                            <img alt={"gouvernance"} src={LogoPlus} className={classes.logoTheme}/>
                            <span className={classes.themeTitleInList}>Gouvernance {calculateScore(resultData.data.theme1)}</span>
                        </div>
                        <div className={classes.subContainerThemes}>
                            <img alt={"gouvernance"} src={LogoPlus} className={classes.logoTheme}/>
                            <span className={classes.themeTitleInList}>Gouvernance {calculateScore(resultData.data.theme1)}</span>
                        </div>
                    </div>*/}
                    {Conclusion && <div className={classes.conclusionText}>{Conclusion}</div>}
                    <Link to={'/informationsRse'}>
                        <Button className={classes.submitButton} onClick={() => setRedirect(true)}>Refaire le
                            questionnaire</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default ResultRse;
