export const postRseResult = (body) => {
    body.User = localStorage.getItem("userId");
    return fetch(`${process.env.REACT_APP_API_URL}/rse/`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body)
    })
        .then((res) => {
            res.json();
        })
};