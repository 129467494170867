import {openNotification} from "./notifications";

export const createFolder = (body) => {
    return fetch(`${process.env.REACT_APP_API_URL}/dossiers`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body)
    })
        .then(async (res) => {
            /*if (res.status === 200) {
                return res.json();
            } else if (res.message) {
                openNotification({
                    type: 'error',
                    message: res.message
                });
            } else {
                openNotification({
                    type: 'error',
                    message: "Une erreur s'est produite"
                });
            }*/
            throw await res.json();
        })
};

export const getFolder = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/dossiers/${localStorage.getItem('userId')}`, {
        method: 'GET',
        credentials: "include",
    })
        .then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.message) {
                openNotification({
                    type: 'error',
                    message: res.message
                });
            } else {
                openNotification({
                    type: 'error',
                    message: "Une erreur s'est produite"
                });
            }
            throw await res.json();
        })
};