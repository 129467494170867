import {USEFOLDER, RESETFOLDER, USECRITERE} from "../types";

const initialState = {
    id: "",
    displayCritere: "",
    stars: 0
};

const folder = (state = initialState, action) => {
    switch (action.type) {
        case USEFOLDER:
            let folderId = JSON.parse(JSON.stringify(action.payload.folderId));
            return({
                id: folderId
            });
        case USECRITERE:
            let critere = JSON.parse(JSON.stringify(action.payload.critere));
            let folderIdCritere = JSON.parse(JSON.stringify(action.payload.folderId));
            let stars = JSON.parse(JSON.stringify(action.payload.stars));
            return({
                displayCritere: critere,
                id: folderIdCritere,
                stars: stars
            });
        case RESETFOLDER:
            return initialState;

        default:
            return state
    }
};

export default folder;
