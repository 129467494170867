import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from 'react-jss';
import { isIOS, isAndroid } from 'react-device-detect';
import style from './AddToHomeScreen_style';
import Image_android1 from "../../images/android1.png";
import Image_android2 from "../../images/android2.png";
import Image_ios1 from "../../images/ios1.png";
import Image_ios2 from "../../images/ios2.png";
import Image_ios3 from "../../images/ios3.png";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = createUseStyles(style);

const AddToHomeScreen = () => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);

    function getTabClass(index) {
        if (selectedTab == index)
            return [classes.tab, classes.selectedTab].join(' ');
        else return classes.tab;
    }

    useEffect(() => {
        if (isIOS) setSelectedTab(1);
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <div className={classes.headerContainer}>
                    <Link to={"/home"}>
                        <IconButton style={{
                            backgroundColor: '#99A0AB',
                            height: 35,
                            width: 35,
                            left: 15,
                            top: 15
                        }}>
                            <HomeIcon className={classes.backIcon}/>
                        </IconButton>
                    </Link>
                </div>
                <div className={classes.title}>Comment installer l'application ?</div>
                <div className={classes.tabsContainer}>
                    <button className={getTabClass(0)} type="button" onClick={() => setSelectedTab(0)}>Android</button>
                    <button className={getTabClass(1)} type="button" onClick={() => setSelectedTab(1)}>IOS</button>
                </div>
                {selectedTab == 0 && <div>
                    <div className={classes.tutorial}>
                            <div className={classes.tutorialTitle}>Etape 1</div>
                            <div className={classes.tutorialText}>
                                Rendez-vous sur le site "global-performance" puis ouvrez le menu de votre navigateur (bouton avec les 3 points).
                            </div>
                        <img className={classes.tutorialImg} src={Image_android1} />
                    </div>
                    <div className={classes.tutorial}>
                            <div className={classes.tutorialTitle}>Etape 2</div>
                            <div className={classes.tutorialText}>
                                Sélectionnez l'option "Ajouter à l'écran d'accueil" puis suivez les instructions de votre appareil.
                            </div>
                        <img className={classes.tutorialImg} src={Image_android2} />
                    </div>
                </div>}
                {selectedTab == 1 && <div>
                    <div className={classes.tutorial}>
                        <div className={classes.tutorialTitle}>Etape 1</div>
                        <div className={classes.tutorialText}>
                            Ouvrez le menu de partage de Safari
                        </div>
                        <img className={classes.tutorialImg} src={Image_ios1} />
                    </div>
                    <div className={classes.tutorial}>
                        <div className={classes.tutorialTitle}>Etape 2</div>
                        <div className={classes.tutorialText}>
                            Sélectionnez l'option "Sur l'écran d'accueil".
                        </div>
                        <img className={classes.tutorialImg} src={Image_ios2} />
                    </div>
                    <div className={classes.tutorial}>
                        <div className={classes.tutorialTitle}>Etape 3</div>
                        <div className={classes.tutorialText}>
                            Vous pouvez changer le nom du raccourci si vous le souaitez. Cliquez sur "Ajouter" et l'icone apparaîtra sur votre page d'accueil.
                        </div>
                        <img className={classes.tutorialImg} src={Image_ios3} />
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default AddToHomeScreen;