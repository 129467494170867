export const calcScoreConnect = (answers) => {
    let montantPartiel = 0;
    const montantTotal = 21;

    if (answers.gestionTechniqueBat === "Oui") {
        montantPartiel += 5;
    }
    if (answers.localoperateur === "Oui") {
        montantPartiel += 1;
    }
    if (answers.reseauinfotechnique === "Oui") {
        montantPartiel += 3;
    }
    if (answers.gtbouverteproprio === "Oui") {
        montantPartiel += 2;
    }
    if (answers.respectRgpd === "Oui") {
        montantPartiel += 1;
    }
    if (answers.managementSmart === "Oui") {
        montantPartiel += 2;
    }
    if (answers.adductionTelecom === "Oui") {
        montantPartiel += 1;
    }
    if (answers.wifiPublic === "Oui") {
        montantPartiel += 3;
    }
    if (answers.raccordementOperateur === "Oui") {
        montantPartiel += 3;
    }

    return (montantPartiel / montantTotal) * 100;
};

export const calcScoreBim = (answers) => {
    const montantTotal = 29;
    let montantPartiel = 0;

    if (answers.competencebim === "Oui") {
        montantPartiel += 5;
    }
    if (answers.maquettebim === "Oui") {
        montantPartiel += 10;
    }
    montantPartiel += answers.objectifsBimConception.length;
    montantPartiel += answers.objectifsBimExploitation.length;
    montantPartiel += answers.objectifsBimExecution.length;

    return (montantPartiel / montantTotal) * 100;
};

export const calcScoreEnvironment = (answers) => {
    const montantTotal = 47;
    let montantPartiel = 0;

    if (answers.recupEauPluie === "Oui, pour l'arrosage") {
        montantPartiel += 1;
    } else if (answers.recupEauPluie === "Oui, pour les sanitaires") {
        montantPartiel += 4;
    } else if (answers.recupEauPluie === "Oui, pour l'arrosage et les sanitaires") {
        montantPartiel += 5;
    }

    for (let i = 0; i <= answers.elementsProjetEnvironement.length; i++) {
        if (answers.elementsProjetEnvironement[i] === "Emplacement pour cycles" ||
            answers.elementsProjetEnvironement[i] === "Douches" ||
            answers.elementsProjetEnvironement[i] === "Vestiaires" ||
            answers.elementsProjetEnvironement[i] === "Bornes de recharge pour VE") {
            montantPartiel += 1;
        } else if (answers.elementsProjetEnvironement[i] === "Places dédiés au covoiturage" ||
            answers.elementsProjetEnvironement[i] === "Plateforme de covoiturage") {
            montantPartiel += 0.5;
        }
    }

    for (let i = 0; i <= answers.energiesRenouvelables.length; i++) {
        if (answers.energiesRenouvelables[i] === "Panneaux photovoltaique" ||
            answers.energiesRenouvelables[i] === "Eolienne urbaine") {
            montantPartiel += 1.5;
        } else if (answers.energiesRenouvelables[i] === "Panneaux solaires (ECS)" ||
            answers.energiesRenouvelables[i] === "Géothermie") {
            montantPartiel += 1;
        } else if (answers.energiesRenouvelables[i] === "Chauferie bois") {
            montantPartiel += 0.5;
        }
    }

    if (answers.gainConsoEnergieRt === "Entre 10 et 20%") {
        montantPartiel += 1;
    } else if (answers.gainConsoEnergieRt === "Entre 20 et 30%") {
        montantPartiel += 2;
    } else if (answers.gainConsoEnergieRt === "Entre 30 et 40%") {
        montantPartiel += 3;
    } else if (answers.gainConsoEnergieRt === "Plus de 40%") {
        montantPartiel += 4;
    } else if (answers.gainConsoEnergieRt === "Bâtiment passif" ||
        answers.gainConsoEnergieRt === "Bâtiment à énergie positive") {
        montantPartiel += 5;
    }

    if (answers.structureBat === "Béton" ||
        answers.structureBat === "Maçonnerie" ||
        answers.structureBat === "Acier" ||
        answers.structureBat === "Mix acier/béton") {
        montantPartiel += 1;
    } else if (answers.structureBat === "Mix bois/béton") {
        montantPartiel += 2;
    } else if (answers.structureBat === "Béton bas carbone") {
        montantPartiel += 3;
    } else if (answers.structureBat === "CLT") {
        montantPartiel += 4;
    } else if (answers.structureBat === "Ossature bois") {
        montantPartiel += 5;
    }

    for (let i = 0; i <= answers.typeProtectionSolaire.length; i++) {
        if (answers.typeProtectionSolaire[i] === "Store intérieurs" ||
            answers.typeProtectionSolaire[i] === "Vitrage à faible facteur solaire") {
            montantPartiel += 1;
        } else if (answers.typeProtectionSolaire[i] === "Brise soleil fixe (façade sud)" ||
            answers.typeProtectionSolaire[i] === "Brise soleil orientable (façade est/ouest)" ||
            answers.typeProtectionSolaire[i] === "Casquettes") {
            montantPartiel += 2;
        } else if (answers.typeProtectionSolaire[i] === "Store toile extérieur") {
            montantPartiel += 1.5;
        }
    }

    if (answers.typeVentilation === "Simple flux") {
        montantPartiel += 1;
    } else if (answers.typeVentilation === "Double flux") {
        montantPartiel += 2;
    }

    if (answers.acousticien === "Oui") {
        montantPartiel += 3;
    }

    for (let i = 0; i <= answers.dispositifBio.length; i++) {
        if (answers.dispositifBio[i] === "Ruches" ||
            answers.dispositifBio[i] === "Nichoirs à oiseaux" ||
            answers.dispositifBio[i] === "Gites à insectes" ||
            answers.dispositifBio[i] === "Toiture végétalisé extensive" ||
            answers.dispositifBio[i] === "Réduction de la pollution lumineuse" ||
            answers.dispositifBio[i] === "Sensibilisation (affichage, actions,..)") {
            montantPartiel += 0.5;
        } else if (answers.dispositifBio[i] === "Toiture végétalisé semi-intensive" ||
            answers.dispositifBio[i] === "Potager urbain") {
            montantPartiel += 1;
        } else if (answers.dispositifBio[i] === "Toiture végétalisé intensive") {
            montantPartiel += 2;
        }
    }

    return (montantPartiel / montantTotal) * 100;
};

export const calcScoreFonctionnel = (answers) => {
    const montantTotal = 72;
    let montantPartiel = 0;

    if (answers.typologie === "Industrie" ||
        answers.typologie === "Logement" ||
        answers.typologie === "Hospitalier" ||
        answers.typologie === "Commerce" ||
        answers.typologie === "Enseignement") {
        montantPartiel += 5;
    }

    if (answers.postestravail > 0) {
        montantPartiel += 5;
        const calcul1 = answers.surface / answers.postestravail;
        if (calcul1 <= 11) {
            montantPartiel += 10;
        } else if (calcul1 <= 13 && calcul1 > 11) {
            montantPartiel += 7;
        } else if (calcul1 <= 15 && calcul1 > 13) {
            montantPartiel += 4;
        }
    }

    if (answers.effectifs > 0) {
        montantPartiel += 5;
    }

    if (answers.plandeplacement === "Oui") {
        montantPartiel += 5;
    }

    if (answers.demarcherse === "Oui") {
        montantPartiel += 10;
    }

    if (answers.typeErp !== "Ne sais pas") {
        montantPartiel += 2;
    }

    if (answers.categorieErp === "5") {
        montantPartiel += 10;
    }

    if (answers.postestravail > 0 && answers.effectifs > 0) {
        const calcul2 = answers.postestravail - answers.effectifs;
        const percent = (calcul2 / answers.postestravail) * 100;
        if (percent <= 10 && percent > 0) {
            montantPartiel += 5;
        } else if (percent > 10 && percent <= 20) {
            montantPartiel += 3;
        } else if (percent > 20 && percent <= 30) {
            montantPartiel += 1;
        }
    }

    if (answers.categorieErp === "1") {
        if (answers.effectifs > 1500) {
            montantPartiel += 10;
        }
    } else if (answers.categorieErp === "2") {
        let tmpCalcul3 = 1500 - answers.effectifs;
        if (tmpCalcul3 < 100) {
            montantPartiel += 10;
        } else if (tmpCalcul3 >= 100 && tmpCalcul3 < 250) {
            montantPartiel += 8;
        } else if (tmpCalcul3 >= 250 && tmpCalcul3 < 400) {
            montantPartiel += 6;
        } else if (tmpCalcul3 >= 400) {
            montantPartiel += 3;
        }
    } else if (answers.categorieErp === "3") {
        let tmpCalcul3 = 700 - answers.effectifs;
        if (tmpCalcul3 < 50) {
            montantPartiel += 10;
        } else if (tmpCalcul3 >= 50 && tmpCalcul3 < 100) {
            montantPartiel += 7;
        } else if (tmpCalcul3 >= 100) {
            montantPartiel += 3;
        }
    } else if (answers.categorieErp === "4") {
        let tmpCalcul3 = 300 - answers.effectifs;
        if (tmpCalcul3 < 50) {
            montantPartiel += 10;
        } else if (tmpCalcul3 >= 50 && tmpCalcul3 < 100) {
            montantPartiel += 7;
        } else if (tmpCalcul3 >= 100) {
            montantPartiel += 3;
        }
    }

    return (montantPartiel / montantTotal) * 100;
};
