export default {
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: 'auto'
    },
    fillStar: {
        width: "auto",
        height: 30,
        marginLeft: 7
    },
    emptyStar: {
        width: "auto",
        height: 30,
        marginLeft: 7
    }
}
