import {LOGIN, LOGOUT, REGISTER} from "../types";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const initialState = {
    isAuth: false
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            cookies.set('sess',true, {path: '/'});
            let user = JSON.parse(JSON.stringify(action.payload));
            localStorage.setItem('userId', user._id);
            return({
                isAuth: true,
                ...user
            });
        case LOGOUT:
            cookies.remove('sess',{path: '/'});
            localStorage.removeItem('userId');
            document.location.reload();
            return initialState;

        default:
            return state
    }
};

export default user;