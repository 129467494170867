import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import style from './forgotPassword_styles';
import {Link, useParams} from 'react-router-dom';
import {Input} from "antd";
import Button from "antd/es/button";
import LogoProjexBlanc from "../../images/LOGO_GLOBAL_PERF_BLANC.png";
import { forgotPassword } from "../../actions/auth";
import {useDispatch} from "react-redux";

const useStyles = createUseStyles(style);

const ForgotPassword = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { token } = useParams();
    const [email, setemail] = useState("")

    const validate = useCallback(() => {
            forgotPassword(email)(dispatch).catch(() => {
                setemail("");
            }).then(() => console.log());
    }, [email]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            validate();
        }
    },[validate]);

    return (
        <div className={classes.container}>
            <div className={classes.formContainer}>
                <span className={classes.loginTitle}>Réinitialiser son mot de passe</span>
                <Input
                    placeholder={"veuillez indiquer votre email"}
                    className={classes.formInput}
                    type={"email"}
                    onChange={e => setemail(e.target.value)}
                    value={email}
                    onKeyPress={handleKeyPress}
                />
            </div>
            <Button onClick={validate} className={classes.button}>
                Envoyer le lien de réinitialisation
            </Button>
            <Link to={"/login"}>
                <p className={classes.SubText}>Se connecter</p>
            </Link>
            <img src={LogoProjexBlanc} className={classes.logo}/>
        </div>
    )
};

export default ForgotPassword;
