export default {
    container: {
        margin: 15,
        height: 150
    },
    batimentTitle: {
        color: '#001942',
        fontWeight: 'bold',
        fontSize: 20,
        textAlign: 'left'
    },
    DeleteIcon: {
        color: "#001942",
        marginBottom: 'auto',
        marginTop: 'auto',
        fontSize: 60
    },
    logo: {
        width: 80,
        height: 80,
        marginLeft: "auto",
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    batimentDescription: {
        marginTop: 15,
        color: '#546C93',
        width: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        maxHeight: 90
    },
    textLogoContainer: {
        display: 'flex'
    },
    textContainer: {
        marginLeft: 15
    },
    containerItem: {
        display: 'flex',
        flexDirection: 'row',
        margin: 15,
    },
    '@media (min-width: 640px)': {
        batimentDescription: {
            width: '70%'
        }
    },
    '@media (max-width: 360px)': {
        textContainer: {
            width: 140
        },
        batimentTitle: {
            fontSize: 15
        },
        logo: {
            height: 50,
            width: 50
        },
        DeleteIcon: {
            marginTop: 10
        }
    },
    '@media (min-width: 720px)': {
        textContainer: {
            width: 300
        }
    },
    '@media (max-width: 605px)': {
        textContainer: {
            width: 150
        }
    }
}
