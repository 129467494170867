export const theme1 = [
    ["Engagement de l’entreprise", "La RSE est inscrite dans le projet de l’entreprise, dans sa stratégie ou dans sa vision"],
    ["Mise en œuvre opérationnelle", "Mon entreprise identifie, mesure et gère ses problématiques RSE à travers un système de management (tableau de bord des indicateurs annuels RSE, KPIs, objectifs, échéances, …) permettant de traiter l’ensemble des thématiques de la RSE (gouvernance, droits de l’Homme, social, environnement, éthique, consommateurs, sociétal)."],
    ["Relations avec les parties prenantes", "Mon entreprise a identifié et dialogue avec ses parties prenantes pour connaître leur besoin. Par la mise en place des processus formels et réguliers pour recueillir des informations auprès des parties prenantes sur leur engagement (sondages, réunions, échanges, …) Mon entreprise a mis en place des procédures pour traiter les résultats et les diffusons de manière officielle."],
    ["Organisation de la veille réglementaire et normative", "Mon entreprise a mis en place un outil ou une procédure de veille permettant de s’assurer de la conformité réglementaire de l’ensemble des activités de l’entreprise aux fins de sécuriser sa croissance, ses résultats et la qualité de ses produits."],
    ["Communication interne et externe", "Mon entreprise diffuse des actus, des newsletters, bilans réguliers sur ses actions et ses résultats RSE dans le but d’impliquer les parties prenantes internes et externes de l’entreprise dans la mise en œuvre de sa stratégie de RSE et valoriser les engagements et les résultats de l’entreprise."]
];

export const theme2 = [
    ["Développement du capital humain et Formation", "Mon entreprise favorise l’innovation et le développement de compétences qui s’adaptent aux évolutions de l’activité. Elle offre des possibilités de formation pour les collaborateurs. Par exemple un processus formel d’intégration pour les nouveaux employés, un catalogue de formation pluridisciplinaire pour la progression verticale et transverse, des formations non spécifiques à une carrière mais au développement personnel."],
    ["Environnement de travail favorable à l’épanouissement des salariés", "Mon entreprise donne aux salariés un cadre de travail qui leur permette de concilier épanouissement personnel et professionnel. Services, animations qui contribuent au bien-être et au confort des collaborateurs. Exemples : Identification d’un référent QVT, animations bien-être (yoga, échauffements, ostéopathe), covoiturage, promotion du vélo et autres modes alternatifs à la voiture, nouvelles offres du CE (club de sport, bien-être), dispositifs d’accompagnement des moments de vie (garde d’enfants, …), etc."],
    ["Dialogue social", "Mon entreprise établie des mécanismes de négociation, de consultation et d’échange d'informations entre représentants du personnel, salariés et membres de son équipe de direction sur les sujets d'intérêt commun de l'entreprise. La reconnaissance mutuelle des intérêts des différentes parties contribue à l’identification de solutions durables pour l’entreprise. Ex : CSE"],
    ["Santé et sécurité au travail", "Mon entreprise sensibilise les collaborateurs aux risques de santé,(Exemples : formations contre les troubles musculosquelettiques, organisation de dépistages diabète, ateliers de prévention des addictions, participation au mois sans tabac, etc.), encourage les collaborateurs à pratiquer des activités sportives Exemples : sensibilisation aux bienfaits du sport, participation à un évènement sportif avec l’entreprise (course, etc.), organisation de séances collectives de course, marche, vélo, etc."],
    ["Equité professionnelle", "Mon entreprise assure et garantie une équité H / F dans le process de recrutement et dans la rémunération de ses collaborateurs."]
];

export const theme3 = [
    ["Prévention de la pollution", "Mon entreprise maîtrise ses impacts environnementaux. Elle met en place des actions permettant de prévenir les pollutions de l’air, de l’eau et des sols et limite le volume et la toxicité des déchets. Elle sensibilise et incite les collaborateurs au tri et à la réduction des déchets. Exemples : poubelles de tri, gobelets et vaisselle réutilisables, affichages sur la zone de tri, affichage des bonnes pratiques et des résultats, etc."],
    ["Utilisation durable des ressources", "Mon entreprise emploie des alternatives à l’utilisation des ressources (eau, matières premières, énergie, matériaux d’emballages et de conditionnement, sols, etc.)\n" +
    "Mon entreprise sait mesurer l’optimisation de l’utilisation des ressources pour réduire son impact sur l’environnement et ainsi son exposition à l’évolution de leur prix et disponibilité.\n" +
    "Mon entreprise met en place des actions permettant de diminuer sa consommation d’eau et d’énergies."],
    ["Atténuation du changement climatique", "Pour limiter ses émissions de gaz à effet de serre, anticiper les effets du changement climatique (canicules, sécheresses, tempêtes, inondations, etc.) mon entreprise contribue à limiter le dérèglement climatique pour ne pas en subir les effets sur son activité.\n" +
    "Mon entreprise participe à l’élaboration du plan de déplacement d’entreprise (PDE) à destination des salariés.\n" +
    "Mon entreprise effectue un suivi des postes d’émissions de gaz à effet de serre et veille à les limiter\n" +
    "Mon entreprise encourage les formes de mobilité douce auprès de mes collaborateurs et clients\n"],
    ["Biodiversité", "Mon entreprise met en place des actions de préservation de la diversité biologique.\n" +
    "Exemples : analyse « biodiversité » du site, plantation de plantes locales/mellifères, mise en prairie de gazons, politique Zéro Phyto, installation de nichoirs à oiseaux et de ruches, etc.\n"],
    ["Mécanismes de compensation", "Mon entreprise, après avoir mis en place des actions de réduction de ses impacts sur l’environnement, réalise une compensation pour viser une neutralité. Ex : compensation des émissions carbones par plantation d’arbres, compensation biodiversité par prairie fleurie, achat de certificat d’économie d’énergies"]
];

export const theme4 = [
    ["Solidarité et interaction de l’entreprise avec son territoire", "Mon entreprise contribue au développement économique de son territoire en lien avec les parties prenantes locales (plus ou moins 50km). Référencement de ses parties prenantes avec identification géographique."],
    ["Impact du business modèle", "Les produits / services de mon entreprise permettent de résoudre / faciliter un problème économique / social pour ou par mes clients."],
    ["Mécénat et sponsoring", "Mon entreprise participe financièrement ou délègue des heures dédiées à des organisations (associations / entreprises à but non lucratif / associations de bien commun) dans l’objectif de renforcer et soutenir les impacts positifs associatifs locaux."],
    ["Création d’emploi locaux", "Mon entreprise contribue activement à la formation et l’emploi des jeunes du territoire \n" +
    "Exemples : partenariats avec les établissements de formation et les écoles locales, accueil d’alternants et de stagiaires, intégration, participation, financement des organisations qui génèrent des emplois locaux ou emplois aidés dans un rayon de 20 km."],
    ["Handicap", "Mon entreprise recrute et favorise l’emploi de personnes en situation de handicap ou éloignées de l’emploi\n" +
    "Exemples : recours au secteur protégé, aux ateliers et chantiers d’insertion (ACI), entreprises d’insertion, pour l’entretien ou les travaux, etc.\n"]
];

export const theme5 = [
    ["Ethique et transparence", "Mon entreprise possède un code de déontologie/éthique (interdiction de cadeaux et pots de vin de manière directe ou indirecte) et prend des mesures de surveillance. En cas de violation des règles d’éthique, des actions sont officiellement prises."],
    ["Lutte contre la corruption", "Mon entreprise lutte contre la corruption en garantissant une transparence et une concurrence loyale vis-à-vis des autres organisations et participe au développement durable de ses territoires d’implantation."],
    ["Relations responsables avec les pouvoirs publics", "Mon entreprise exerce un lobbying responsable auprès des pouvoirs publics et s’interdit tout abus d'autorité. Mon entreprise inscrit son développement au service de l’intérêt général et associe les pouvoirs publics aux projets et activités de l’entreprise."],
    ["Concurrence loyale et respect des droits de propriété", "Mon entreprise développe une concurrence loyale dans ses pratiques afin de respecter la libre concurrence et veiller au respect des droits de propriété intellectuelle permettant de stimuler l'innovation et l'efficience dans le cadre d’une compétition saine."],
    ["Achats responsables", "Mon entreprise établie une charte envers ses fournisseurs pour développer des achats de produits responsables. Elle incite ses fournisseurs, prestataires et sous-traitants à mener le même type de démarches à leur niveau et auprès de leur propre chaîne d’approvisionnement.\n" +
    "Mon entreprise choisie des consommables (produits d’entretien, papier, etc.) responsables. Exemples : produits labellisés (NF Environnement, Ecolabel, PEFC, FSC, etc.)"]
];

export const RseSupp80 = "Félicitations, vous avez obtenu la note de VAR_SCORE% ! Vos pratiques RSE au sein de votre organisation sont structurées."
+" Vous avez réussi à déployer vos actions sur l’ensemble des thèmes et à tout niveau de l’organisation et de votre écosystème. Si vous souhaitez en"
+" échanger ou voir ce qui pourrait être challengé, vous pouvez contacter nos spécialistes via l’adresse mail : rse@groupe-projex.fr";

export const RseSupp50 = "Bravo, vous avez obtenu la note de VAR_SCORE% ! votre organisation est engagée dans la démarche et vous avez mis l’accent sur des actions autour du thème VAR_FIRSTSCORE. \n" +
    "En revanche, votre approche demanderait à déployer et structurer des actions autour des thèmes VAR_FIFTHSCORE et VAR_FOURTHSCORE.\n" +
    "Si vous souhaitez en échanger ou voir ce qui pourrait être challengé, vous pouvez contacter nos spécialistes via l’adresse mail : rse@groupe-projex.fr \n";

export const RseLess50 = "Vous avez obtenu la note de VAR_SCORE% ! C’est un bon début et il faut souligner votre démarche menée sur des actions autour du thème VAR_FIRSTSCORE.\n" +
    "L’avantage, c’est que vous avez une marge de progrès importante possible dans plusieurs domaines, en particulier sur les thèmes VAR_FIFTHSCORE, VAR_FOURTHSCORE et VAR_THIRDSCORE.\n" +
    "Si vous souhaitez en échanger ou voir ce qui pourrait être challengé, vous pouvez contacter nos spécialistes via l’adresse mail : rse@groupe-projex.fr\n";
