import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Switch, withRouter } from "react-router-dom";
import { auth } from "./actions/auth";
import { useDispatch, useSelector } from "react-redux";
import AuthWrapper from "./components/auth_wrapper/auth_wrapper";
import NotFound from "./pages/404/404";
import Cookies from 'universal-cookie';
import { openNotification } from "./actions/notifications";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Register from "./pages/register/register";
import Profile from "./pages/profile/profile";
import BatForm from "./pages/batForm/batForm";
import FolderResult from "./pages/folderResult/folderResult";
import CritereResult from "./pages/critereResult/critereResult";
import Loading from "./components/loading/loading";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import AddRse from "./pages/addRse/AddRse";
import ResultRse from "./pages/resultRse/ResultRse";
import InformationsRse from "./pages/InformationsRse/InformationsRse";
import axios from "axios";
import { ADDTHEME1, FILLSTATE } from "./types";
import AddToHomeScreen from './pages/AddToHomeScreen/AddToHomeScreen';

const cookies = new Cookies();

const App = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        if (cookies.get('sess')) {
            auth()(dispatch)
                .then((user) => setTimeout(() => {
                    if (!user.isAuth) {
                        openNotification({
                            type: 'error',
                            message: 'Authentication failed',
                            description: 'Can\`t reconnect to session, try to relog your account.'
                        });
                    } else {
                        axios.get(`${process.env.REACT_APP_API_URL}/rse/${user.user._id}`, { withCredentials: true })
                            .then(res => {
                                dispatch({
                                    type: FILLSTATE,
                                    payload: res.data
                                });
                            });
                    }
                }, 1000));
        }
    }, []);
    return (
        <div className="App">
            <Switch>
                <Route exact path="/" component={(props) => <Login {...props} />} />
                <Route exact path="/home" component={(props) => <AuthWrapper><Home {...props} /></AuthWrapper>} />
                <Route exact path="/profile" component={(props) => <AuthWrapper><Profile {...props} /></AuthWrapper>} />
                <Route exact path="/newbat" component={(props) => <AuthWrapper><BatForm {...props} /></AuthWrapper>} />
                <Route exact path="/folderResult" component={(props) => <AuthWrapper><FolderResult {...props} /></AuthWrapper>} />
                <Route exact path="/critereResult" component={(props) => <AuthWrapper><CritereResult {...props} /></AuthWrapper>} />
                <Route exact path="/login" component={(props) => <Login {...props} />} />
                <Route path="/addrse/:themeid" component={(props) => <AuthWrapper><AddRse {...props} key={window.location.pathname} /></AuthWrapper>} />
                <Route exact path="/addrsee/:themeid" component={withRouter(AddRse)} />
                <Route exact path={`/resultRse`} component={(props) => <AuthWrapper><ResultRse {...props} /></AuthWrapper>} />
                <Route exact path={`/informationsRse`} component={(props) => <AuthWrapper><InformationsRse {...props} /></AuthWrapper>} />
                <Route exact path={`/howtoinstall`} component={(props) => <AuthWrapper><AddToHomeScreen {...props} /></AuthWrapper>} />
                <Route exact path="/register" component={(props) => <Register {...props} />} />
                <Route exact path="/testLoading" component={(props) => <Loading {...props} />} />
                <Route exact path="/resetPassword/:token" component={(props) => <ResetPassword {...props} />} />
                <Route exact path="/forgotPassword" component={(props) => <ForgotPassword {...props} />} />
                <Route path="/404" component={(props) => <NotFound />} />
            </Switch>
        </div>
    );
};

export default App;
