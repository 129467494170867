export default {
    container: {
        position: " fixed",
        padding: 0,
        margin: 'auto',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        overflow: 'auto',
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        animation: '15s ease 0s infinite $backgroundAnime'
    },
    '@keyframes backgroundAnime': {
        '0%': {
            backgroundPosition: '0% 50%',
        },
        '50%': {
            backgroundPosition: '100% 50%',
        },
        '100%': {
            backgroundPosition: '0% 50%',
        }
    },
    button: {
        borderRadius: 20,
        marginTop: 80,
        width: "80%",
        height: 50,
        fontSize: 15,
        fontWeight: "bold",
        color: '#001942'
    },
    SubText: {
        fontSize: 12,
        color: "white",
        marginTop: 5
    },
    inputContainer: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    formInput: {
        marginTop: 20,
        backgroundColor: "#ECECEC",
        borderRadius: 7
    },
    loginTitle: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 27,
        marginBottom: 20
    },
    formContainer: {
        width: "80%",
        margin: 'auto',
        marginTop: 80
    },
    logo: {
        width: 250,
        marginTop: 190
    },
    forgotPassword: {
        cursor: 'pointer',
        margin: 'auto',
        width: 'max-content'
    },
    '@media (max-height: 730px)':
    {
        logo: {
            marginTop: 100
        }
    }
    ,
    '@media (max-height: 640px)':
    {
        logo: {
            marginTop: 60
        }
        ,
        formContainer: {
            marginTop: 50
        }
        ,
        button: {
            marginTop: 50
        }
    },
    '@media (min-width: 800px)':
    {
        formContainer: {
            width: '50%'
        }
        ,
        button: {
            fontSize: 20,
            width: '50%'
        }
        ,
        SubText: {
            fontSize: 15
        }
    },
    '@media (min-width: 1200px)':
    {
        formContainer: {
            width: '30%'
        }
        ,
        button: {
            fontSize: 20,
            width: '30%'
        }
        ,
        SubText: {
            fontSize: 15
        }
    }
}
