import {LOGIN, LOGOUT} from "../types";
import {openNotification, openNotificationActiveAcount} from "./notifications";

export const auth = () => async dispatch => {
    return fetch(`${process.env.REACT_APP_API_URL}/auth`, {
        method: 'GET',
        credentials: "include",
    })
        .then(async (res) => {
            if (res.status === 200) return res.json();
            throw await res.json();
        })
        .then((res) => {
            if (res.isAuth) {
                dispatch({
                    type: LOGIN,
                    payload: res.user
                });
                return res;
            }
            dispatch({
                type: LOGOUT,
            });
            return res;

        })
        .catch((err) => {
            throw err;
        })

};

const sendMail = (email) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/sendActivateMail`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            email
        })
    })
        .then(async (res) => {
            if (res.status === 200) {
                openNotification({
                    type: 'success',
                    message: 'Email envoyé'
                })
            }
            throw await res.json();
        })
        .then((res) => {
            openNotification({
                type: 'success',
                message: 'Email envoyé'
            })
        })
        .catch((err) => {
            openNotification({
                type: 'error',
                message: err.message
            });
            throw err;
        })
};

export const forgotPassword = (email) => async dispatch => {
    return fetch(`${process.env.REACT_APP_API_URL}/auth/forgot`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            email
        })
    })
        .then(async (res) => {
            if (res.status === 200) return res.json();
            else if (res.status === 300) openNotification({
                type: 'warning',
                message: 'Utilisateur non trouvé'
            })
            throw await res.json();
        })
        .then(() => openNotification({
            type: 'success',
            message: 'Email bien envoyé'
        }))
        .catch((err) => {
            console.log(err);
            throw err;
        });
};

export const resetPassword = (password, token) => async dispatch => {
    return fetch(`${process.env.REACT_APP_API_URL}/auth/reset/${token}`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            password
        })
    })
        .then(async (res) => {
            if (res.status === 200) return res.json();
            throw await res.json();
        })
        .then(() => {openNotification({
            type: 'success',
            message: 'Mot de passe bien changé'
        }); window.location = "/login";})
        .catch((err) => {
            openNotification({
                type: 'error',
                message: err.message
            });
            throw err;
        });
};

export const login = (email, password) => async dispatch => {
    if (!email.length || !password.length) {
        openNotification({
            type: 'error',
            message: 'all fields are mandatory'
        });
        throw {};
    }
    return fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            email,
            password
        })
    })
        .then(async (res) => {
            if (res.status === 200) {
                return res.json();
            }
            throw await res.json();
        })
        .then((res) => {
            return dispatch({
                type: LOGIN,
                payload: res.data
            })
        })
        .catch((err) => {
            openNotification({
                type: 'error',
                message: err.message,
                email: email
            });
            throw err;
        })
};

export const register = (email, password, firstname, lastname, entreprise) => async dispatch => {
    if (password.length < 6) {
        openNotification({
            type: 'error',
            message: 'password must be at least 6 characters '
        });
        throw Error();
    }
    if (!(/\S+@\S+\.\S+/.test(email))) {
        openNotification({
            type: 'error',
            message: 'Email badly formated'
        });
        throw Error();
    }

    return fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            email,
            password,
            firstname,
            lastname,
            entreprise
        })
    })
        .then(async (res) => {
            if (res.status === 201) return res.json();
            throw await res.json();
        })
        .then(() => openNotification({
            type: 'success',
            message: 'Le compte à bien été créé, veuillez activer votre compte via la mail envoyé'
        }))
        .catch((err) => {
            openNotification({
                type: 'error',
                message: err.message
            });
            throw err;
        });
};

export const logout = () => dispatch => {
    return fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        method: 'GET',
        credentials: "include",
    })
        .then(async (res) => {
            if (res.status === 200) return res.json();
            throw await res.json();
        })
        .then((res) => {
            dispatch({
                type: LOGOUT,
            });
            return res

        })
        .catch((err) => {
            throw err;
        })
};

export const sendActivateMail = (email) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/sendActivateMail`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            email
        })
    })
        .then((res) => {
            if (res.status === 200) {
                openNotification({
                    type: 'success',
                    message: 'Email envoyé'
                })
                return res.json();
            }
            throw res.json();
        })
        .then(() => openNotification({
            type: 'success',
            message: 'Email envoyé'
        }))
        .catch((err) => {
            console.log(err)
            throw err;
        });
};