import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import style from './ThemeQuestion_styles';
import { Radio, Input } from 'antd';

const useStyles = createUseStyles(style);

const ThemeQuestion = (props) => {
    const classes = useStyles();
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const onChange = e => {
        props.setanswer(e.target.value);
    };

    return (
        <div className={classes.container}>
            <h3 className={classes.questionTitle}>{props.number}. {props.title}</h3>
            <span className={classes.description}>{props.description}</span>
            <Radio.Group className={classes.radioGroupe} onChange={onChange} value={props.answer}>
                <Radio style={radioStyle} value={1}>
                    Prise de conscience
                </Radio>
                <Radio style={radioStyle} value={2}>
                    Initiation
                </Radio>
                <Radio style={radioStyle} value={3}>
                    Maitrise
                </Radio>
                <Radio style={radioStyle} value={4}>
                    Exemplarité
                </Radio>
            </Radio.Group>
        </div>
    )
};

export default ThemeQuestion;
