import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import style from './home_styles';
import {logout} from "../../actions/auth";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LogoPlus from "../../images/+.png";
import LogoDigitaSolutions from "../../images/LOGO-FINAL-DS-03_1024.png";
import HomeBatimentItem from "../../components/homeBatimentItem/homeBatimentItem";
import {getFolder} from "../../actions/dossier";
import Contact from "../contact/contact";
import {USEFOLDER} from "../../types";
import '../../components/loading/rotateLoading.css';
import Loading from "../../components/loading/loading";
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = createUseStyles(style);

const Home = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [user, setuser] = useState();
    const [batiments, setbatiments] = useState();
    const [displayContact, setdisplayContact] = useState(false);
    const [redirectFolderResult, setredirectFolderResult] = useState(false);
    const [RedirectInformation, setRedirectInformation] = useState(false);
    const [RedirectInstall, setRedirectInstall] = useState(false);
    const [redirectLogin, setRedirectLogin] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/auth`, {
            method: 'GET',
            credentials: "include",
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        }).then(res => res.json())
            .then(res => {
                if (res.isAuth) {
                    setuser(res.user);
                } else {
                    //setRedirectLogin(true);
                    console.log("vous n'etes pas connecté à l'application");
                }
            });

        /*setbatiments([]);*/
        getFolder()
            .then(res => setbatiments(res))
            .catch(err => console.log("fetch folder error", err));
    }, []);

    const setclasses = () => {
        if (batiments.length < 2) {
            return ([classes.logoContainer, classes.absoluteposition].join(' '));
        } else {
            return (classes.logoContainer);
        }
    };

    const refreshFolderList = () => {
        getFolder()
            .then(res => setbatiments(res))
            .catch(err => console.log("fetch folder error", err));
    };

    const goToFolderResult = (id) => {
        dispatch({
            type: USEFOLDER,
            payload: {folderId: id}
        });
        setredirectFolderResult(true);
    };


    if (redirectFolderResult) {
        return (<Redirect to={'/folderResult'}/>)
    } else if (displayContact) {
        return (<Contact setdisplayContact={(param) => setdisplayContact(param)}/>);
    } else if (redirectLogin) {
        return (<Redirect to={'/login'}/>);
    } else if (RedirectInformation) {
        return (<Redirect to={'/informationsRse'}/>);
    }else if (RedirectInstall) {
        return (<Redirect to={'/howtoinstall'}/>);
    } else if (batiments && user) {
        return (
            <div className={classes.container}>
                <div className={classes.subContainer}>
                    <button className={classes.installButton} type="button" onClick={() => setRedirectInstall(true)}>
                        Installer
                    </button>
                    <div className={classes.nameLogoutContainer}>
                        <div className={classes.userName}>{user.Prenom} {user.Nom}</div>
                        <IconButton onClick={() => logout()(dispatch)}>
                            <ExitToAppIcon className={classes.logoutIcon}/>
                        </IconButton>
                    </div>
                    <div className={classes.subTitle}>{user.Entreprise}</div>
                    <Link to={"/newbat"} className={classes.addBatContainer}>
                        <img src={LogoPlus} className={classes.addLogo}/>
                        <div className={classes.addBatTitle}>Ajouter un dossier</div>
                    </Link>
                    <div className={classes.rseContainer}>
                        {user.scoreRse > 0 && <div className={classes.rseBlock}>
                            <div className={classes.subContainerRse}>
                                <div className={classes.leftPart}>
                                    <div className={classes.scoreTitle}>Score RSE</div>
                                    <Link to={"/resultrse"}>Voir le détail</Link>
                                </div>
                                <div className={classes.scoreNumber}>
                                    {user.scoreRse}%
                                </div>
                                <AutorenewIcon onClick={() => setRedirectInformation(true)}
                                               className={classes.RemakeIcon}/>
                            </div>
                        </div>}
                        {!user.scoreRse &&
                        <Link to={"/informationsRse"}>
                            <div className={classes.subContainerRse}>
                                <div className={classes.scoreTitle} style={{margin: 'auto'}}>
                                    Cliquez ici pour évaluer la performance RSE de votre organisation
                                </div>
                            </div>
                        </Link>}
                    </div>
                    {batiments.length === 0 &&
                    <div className={classes.emptyFolderMessage}>Vous n'avez pas encore de dossiers</div>}
                    <div className={classes.batContainer}>
                        {batiments.length > 0 && batiments.map((bat, index) => (
                            <div key={index} style={{cursor: 'pointer'}}>
                                <HomeBatimentItem refresh={() => refreshFolderList()}
                                                  click={() => goToFolderResult(bat._id)} bat={bat}/>
                            </div>
                        ))}
                    </div>
                    {/*<div className={classes.addBatContainer}
                     onClick={() => ReactDOM.render(<BatForm/>, document.getElementById('root'))}>
                    <img src={LogoPlus} className={classes.addLogo}/>
                    <div className={classes.addBatTitle}>Ajouter un bâtiment</div>
                </div>*/}

                    <div className={setclasses()}>
                        <img onClick={() => setdisplayContact(true)} src={LogoDigitaSolutions} className={classes.logo} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Loading/>);
    }
};

export default Home;
