import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import style from './contact_style';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = createUseStyles(style);

const Contact = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <div className={classes.headerContainer}>
                    <IconButton style={{
                        backgroundColor: '#99A0AB',
                        height: 35,
                        width: 35,
                        left: 15,
                        top: 15
                    }} onClick={() => props.setdisplayContact(false)}>
                        <ArrowBackIosIcon className={classes.backIcon}/>
                    </IconButton>
                </div>
                <h3 className={classes.contactTitle} >Contact</h3>
                <div className={classes.formContainer}>
                    <div className={classes.contactInfo}>MERCURY - 30 PLACE SALVADOR ALLENDE 59650 VILLENEUVE D'ASCQ +33 03 20 47 03 01</div>
                    <div className={classes.contactInfo}>LE COLOMBUS - 1 ROND POINT DE L'EUROPE 92250 LA GARENNE COLOMBES +33 01 56 83 76 10</div>
                </div>
            </div>
        </div>
    )
};

export default Contact;