import React, {useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import style from './folderResult_style';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {openNotification} from "../../actions/notifications";
import Logo1s from '../../images/LABEL 1 ETOILE_512.png';
import Logo2s from '../../images/LABEL 2 ETOILES_512.png';
import Logo3s from '../../images/LABEL 3 ETOILES_512.png';
import Logo4s from '../../images/LABEL 4 ETOILES_512.png';
import Logo5s from '../../images/LABEL 4 ETOILES_512.png';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css'
import LogoProjexBlanc from "../../images/LOGO-FINAL-DS-03_1024.png";
import Contact from "../contact/contact";
import {USECRITERE} from "../../types";
import Loading from "../../components/loading/loading";
import StarScoreEnv from "../../components/starScoreEnv/starScoreEnv";
import StarScoreBim from "../../components/starScoreBim/starScoreBim";
import StarScoreFonctionnel from "../../components/starScoreFonctionnel/starScoreFonctionnel";
import StarScoreConnect from "../../components/starScoreConnect/starScoreConnect";
import Chart from "react-apexcharts";
import StarScore from "../../components/starScore/starScore";

const useStyles = createUseStyles(style);

const FolderResult = (props) => {
    const classes = useStyles();
    const {id} = useSelector(store => store.folder);
    const dispatch = useDispatch();
    const [redirect, setredirect] = useState(false);
    const [dossierInfos, setdossierInfos] = useState({});
    const [displayContact, setdisplayContact] = useState(false);
    const [starConnect, setstarConnect] = useState(0);
    const [starBim, setstarBim] = useState(0);
    const [starEnvironement, setstarEnvironement] = useState(0);
    const [starFonctionnel, setstarFonctionnel] = useState(0);
    const [logo, setlogo] = useState();
    const [redirectCritereResult, setredirectCritereResult] = useState(false);
    const [data, setdata] = useState();
    const [series, setSeries] = useState();
    const [options, setOptions] = useState();

    const captions = {
        bim: 'BIM',
        environnement: 'Environnement',
        connectivite: 'Connectivité',
        fonctionnel: 'Fonctionnel'
    };

    const parseDossierInfos = (infos) => {
        setdossierInfos(infos);


        setSeries([{
            name: dossierInfos.Nom,
            data: [
                infos.ScoreBim,
                infos.ScoreEnvironnement,
                infos.ScoreConnectivite,
                infos.ScoreFonctionnel
            ],
        }]);

        setOptions({
            chart: {
                height: 350,
                type: 'radar',
            },
            title: {
                text: dossierInfos.Nom
            },
            xaxis: {
                categories: [
                    'BIM',
                    'Environnement',
                    'Connectivité',
                    'Fonctionnel'
                ]
            }
        });

        setdata([
            {
                data: {
                    bim: infos.ScoreBim / 100,
                    environnement: infos.ScoreEnvironnement / 100,
                    connectivite: infos.ScoreConnectivite / 100,
                    fonctionnel: infos.ScoreFonctionnel / 100
                },
                meta: {color: 'blue'}
            }
        ]);

        switch (infos.Icon) {
            case 1:
                setlogo(Logo1s);
                break;
            case 2:
                setlogo(Logo2s);
                break;
            case 3:
                setlogo(Logo3s);
                break;
            case 4:
                setlogo(Logo4s);
                break;
            case 5:
                setlogo(Logo5s);
                break;
            default:
                setlogo(Logo1s);
                break;
        }

        if (infos.ScoreConnectivite < 20)
            setstarConnect(1);
        else if (infos.ScoreConnectivite >= 20 && infos.ScoreConnectivite < 40)
            setstarConnect(2);
        else if (infos.ScoreConnectivite >= 40 && infos.ScoreConnectivite < 60)
            setstarConnect(3);
        else if (infos.ScoreConnectivite >= 60 && infos.ScoreConnectivite < 80)
            setstarConnect(4);
        else if (infos.ScoreConnectivite >= 80)
            setstarConnect(5);

        if (infos.ScoreBim < 20)
            setstarBim(1);
        else if (infos.ScoreBim >= 20 && infos.ScoreBim < 40)
            setstarBim(2);
        else if (infos.ScoreBim >= 40 && infos.ScoreBim < 60)
            setstarBim(3);
        else if (infos.ScoreBim >= 60 && infos.ScoreBim < 80)
            setstarBim(4);
        else if (infos.ScoreBim >= 80)
            setstarBim(5);

        if (infos.ScoreEnvironnement < 20)
            setstarEnvironement(1);
        else if (infos.ScoreEnvironnement >= 20 && infos.ScoreEnvironnement < 40)
            setstarEnvironement(2);
        else if (infos.ScoreEnvironnement >= 40 && infos.ScoreEnvironnement < 60)
            setstarEnvironement(3);
        else if (infos.ScoreEnvironnement >= 60 && infos.ScoreEnvironnement < 80)
            setstarEnvironement(4);
        else if (infos.ScoreEnvironnement >= 80)
            setstarEnvironement(5);

        if (infos.ScoreFonctionnel < 20)
            setstarFonctionnel(1);
        else if (infos.ScoreFonctionnel >= 20 && infos.ScoreFonctionnel < 40)
            setstarFonctionnel(2);
        else if (infos.ScoreFonctionnel >= 40 && infos.ScoreFonctionnel < 60)
            setstarFonctionnel(3);
        else if (infos.ScoreFonctionnel >= 60 && infos.ScoreFonctionnel < 80)
            setstarFonctionnel(4);
        else if (infos.ScoreFonctionnel >= 80)
            setstarFonctionnel(5);
    };

    useEffect(() => {
        if (!id) {
            setredirect(true);
        }

        if (id) {
            fetch(`${process.env.REACT_APP_API_URL}/dossiers/infos/${id}`, {
                method: 'GET',
                credentials: "include",
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            }).then(res => res.json())
                .then(res => {
                    if (res) {
                        parseDossierInfos(res[0]);
                    }
                });
        }
    }, [id]);

    const goToCritereResult = (id, critere) => {
        let sendStars = 0;
        if (critere === "Connectivité")
            sendStars = starConnect;
        else if (critere === "BIM")
            sendStars = starBim;
        else if (critere === "Environnement")
            sendStars = starEnvironement;
        else if (critere === "Fonctionnel")
            sendStars = starFonctionnel;
        dispatch({
            type: USECRITERE,
            payload: {folderId: id, critere, stars: sendStars}
        });
        setredirectCritereResult(true);
    };

    if (redirectCritereResult) {
        return (<Redirect to={"/critereResult"}/>);
    } else if (redirect) {
        openNotification({
            type: 'warning',
            message: "Veuillez selectionner un dossier pour acceder à cette page"
        });
        return (<Redirect to={"/home"}/>);
    } else if (displayContact) {
        return (<Contact setdisplayContact={(param) => setdisplayContact(param)}/>);
    } else if (starConnect && starBim && starEnvironement && starFonctionnel && logo) {
        return (
            <div className={classes.container}>
                <div className={classes.subContainer}>
                    <div className={classes.headerContainer}>
                        <Link to={"/home"}>
                            <IconButton style={{
                                backgroundColor: '#99A0AB',
                                height: 35,
                                width: 35,
                                left: 15,
                                top: 15
                            }}>
                                <ArrowBackIosIcon className={classes.backIcon}/>
                            </IconButton>
                        </Link>
                        <div className={classes.headerTitleContainer}>
                            <span className={classes.batimentName}>{dossierInfos.Nom}</span>
                            <span>{dossierInfos.Adresse}</span>
                        </div>
                        <img alt={"label"} src={logo} className={classes.logo}/>
                        {/*<div className={classes.textLogoContainer}>
                        </div>*/}
                    </div>
                    <div className={classes.whiteContainer}>
                        {/*{data && captions && <RadarChart
                            captions={captions}
                            data={data}
                            size={300}
                            options={{
                                captionMargin: 30
                            }}
                        />}*/}
                        <div className={classes.starsTitle}>
                            <StarScore score={dossierInfos.Icon} total={5}/>
                        </div>
                        {series && options && <Chart options={options} series={series} type="radar" style={{color: 'black'}} height={400} />}
                        <div style={{cursor: 'pointer'}} onClick={() => goToCritereResult(dossierInfos._id, "Connectivité")}>
                            <div className={classes.critereRow}>
                                <span className={classes.critereTitle}>Connectivité</span>
                                <div>
                                    <StarScoreConnect score={starConnect} total={5}/>
                                </div>
                            </div>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => goToCritereResult(dossierInfos._id, "BIM")}>
                            <div className={classes.critereRow}>
                                <span className={classes.critereTitle}>BIM</span>
                                <div className={classes.starContainer}>
                                    <StarScoreBim score={starBim} total={5}/>
                                </div>
                            </div>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => goToCritereResult(dossierInfos._id, "Environnement")}>
                            <div className={classes.critereRow}>
                                <span className={classes.critereTitle}>Environnement</span>
                                <div className={classes.starContainer}>
                                    <StarScoreEnv score={starEnvironement} total={5}/>
                                </div>
                            </div>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => goToCritereResult(dossierInfos._id, "Fonctionnel")}>
                            <div className={classes.critereRow}>
                                <span className={classes.critereTitle}>Fonctionnel</span>
                                <div className={classes.starContainer}>
                                    <StarScoreFonctionnel score={starFonctionnel} total={5}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.logoContainer}>
                        <img onClick={() => setdisplayContact(true)} src={LogoProjexBlanc}
                             className={classes.logoBottom}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Loading/>);
    }
};

export default FolderResult;
