import { combineReducers } from 'redux';
import user from './user';
import folder from "./folder";
import rse from "./rse";

const rootReducer = combineReducers({
    user,
    folder,
    rse
});

export default rootReducer;
