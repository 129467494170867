export default {
    container: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40
    },
    description: {
        color: 'black'
    },
    questionTitle: {
        fontWeight: 'bold'
    },
    radioGroupe: {
        marginTop: 15
    }
}