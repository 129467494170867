export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        color: 'white',
        margin: 'auto',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        animation: '15s ease 0s infinite $backgroundAnime'
    },
    '@keyframes backgroundAnime': {
        '0%':{
            backgroundPosition: '0% 50%',
        },
        '50%':{
            backgroundPosition: '100% 50%',
        },
        '100%':{
            backgroundPosition: '0% 50%',
        }
    },
    subContainer: {
        width: "100%",
        margin: 'auto'
    },
    backIcon: {
        color: 'white',
        marginLeft: 5
    },
    formContainer: {
        backgroundColor: 'white',
        borderRadius: 15,
        marginTop: 30,
        marginBottom: 35,
        overflow: 'auto',
        marginRight: 10,
        marginLeft: 10,
        padding: 25
    },
    contactInfo: {
        marginBottom: 10,
        textAlign: 'left',
        color: '#273D5F',
        fontWeight: 'bold'
    },
    contactTitle: {
        textAlign: 'left',
        color: 'white',
        marginTop: 65,
        marginLeft: 15,
        fontWeight: 'bold'
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    '@media (min-width: 500px)': {
        subContainer: {
            width: '70%'
        }
    },
    '@media (min-width: 1200px)': {
        subContainer: {
            width: '50%'
        }
    }
}