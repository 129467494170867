export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        color: 'white',
        margin: 'auto',
        top: 0,
        left: 0,
        backgroundImage: 'linear-gradient(to bottom left, #00050D 20%, #001942 60%)',
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans'
    },
    subContainer: {
        width: "100%",
        position: 'absolute',
        bottom: '10px',
    },
    loader:{
        width: "50%",
        maxHeight: "100vh",
        maxWidth: "100vh"
    }
}