import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';

const NotFound = () => {
    return (
        <div>
            <div>
                Oups.. Cette page est introuvable
            </div>
        </div>
    )
};

export default NotFound;