import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import {Link} from 'react-router-dom';
import {createFolder} from "../../actions/dossier";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import style from './batForm_styles';
import IconButton from "@material-ui/core/IconButton";
import {
    Form,
    Radio,
    Input,
    Select,
    Button,
    InputNumber
} from "antd";
import {Redirect} from "react-router-dom";
import {openNotification} from "../../actions/notifications";
import {calcScoreBim, calcScoreConnect, calcScoreEnvironment, calcScoreFonctionnel} from "../../actions/calc";
import {USEFOLDER} from "../../types";
import {useDispatch} from "react-redux";

const {Option} = Select;

const useStyles = createUseStyles(style);

const BatForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [curentStep, setcurentStep] = useState(1);
    const [totalStep, settotalStep] = useState(4);
    const [StepTitle, setStepTitle] = useState("Informations générales");
    const [redirectHome, setredirectHome] = useState(false);
    const [redirectFolderResult ,setredirectFolderResult] = useState(false);

    // Formulaire states
    const [folderName, setfolderName] = useState("");
    const [status, setstatus] = useState("");
    const [adresse, setadresse] = useState("");
    const [usager, setusager] = useState("");
    const [surface, setsurface] = useState("");
    const [typologie, settypologie] = useState("");
    const [phase, setphase] = useState("");
    const [classement, setclassement] = useState("");
    const [neufreno, setneufreno] = useState("");

    const [competencebim, setcompetencebim] = useState("");
    const [maquettebim, setmaquettebim] = useState("");
    const [postestravail, setpostestravail] = useState("0");
    const [effectifs, seteffectifs] = useState("0");
    const [plandeplacement, setplandeplacement] = useState("");
    const [demarcherse, setdemarcherse] = useState("");
    const [typeErp, settypeErp] = useState("");
    const [categorieErp, setcategorieErp] = useState("");

    const [objectifsBimConception, setobjectifsBimConception] = useState([]);
    const [objectifsBimExecution, setobjectifsBimExecution] = useState([]);
    const [objectifsBimExploitation, setobjectifsBimExploitation] = useState([]);
    const [recupEauPluie, setrecupEauPluie] = useState("");
    const [elementsProjetEnvironement, setelementsProjetEnvironement] = useState([]);
    const [gestionTechniqueBat, setgestionTechniqueBat] = useState("");
    const [energiesRenouvelables, setenergiesRenouvelables] = useState([]);
    const [gainConsoEnergieRt, setgainConsoEnergieRt] = useState("");
    const [structureBat, setstructureBat] = useState("");
    const [typeProtectionSolaire, settypeProtectionSolaire] = useState([]);
    const [typeVentilation, settypeVentilation] = useState("");
    const [acousticien, setacousticien] = useState("");
    const [dispositifBio, setdispositifBio] = useState([]);

    const [localoperateur, setlocaloperateur] = useState("");
    const [reseauinfotechnique, setreseauinfotechnique] = useState("");
    const [gtbouverteproprio, setgtbouverteproprio] = useState("");
    const [respectRgpd, setrespectRgpd] = useState("");
    const [managementSmart, setmanagementSmart] = useState("");
    const [adductionTelecom, setadductionTelecom] = useState("");
    const [wifiPublic, setwifiPublic] = useState("");
    const [raccordementOperateur, setraccordementOperateur] = useState("");
    const [normespmr, setnormespmr] = useState("");

    const onFinishFourthStep = values => {

        setlocaloperateur(values.localoperateur);
        setreseauinfotechnique(values.reseauinfotechnique);
        setgtbouverteproprio(values.gtbouverteproprio);
        setrespectRgpd(values.respectRgpd);
        setmanagementSmart(values.managementSmart);
        setadductionTelecom(values.adductionTelecom);
        setwifiPublic(values.wifiPublic);
        setraccordementOperateur(values.raccordementOperateur);
        setnormespmr(values.normespmr);

        let body = {};
        body.status = status;
        body.adresse = adresse;
        body.usager = usager;
        body.surface = surface;
        body.typologie = typologie;
        body.phase = phase;
        body.classement = classement;
        body.nom = folderName;
        body.neufreno = neufreno;
        body.competencebim = competencebim;
        body.maquettebim = maquettebim;
        body.postestravail = postestravail;
        body.effectifs = effectifs;
        body.plandeplacement = plandeplacement;
        body.demarcherse = demarcherse;
        body.typeErp = typeErp;
        body.categorieErp = categorieErp;
        body.objectifsBimConception = objectifsBimConception;
        body.objectifsBimExecution = objectifsBimExecution;
        body.objectifsBimExploitation = objectifsBimExploitation;
        body.recupEauPluie = recupEauPluie;
        body.elementsProjetEnvironement = elementsProjetEnvironement;
        body.gestionTechniqueBat = gestionTechniqueBat;
        body.energiesRenouvelables = energiesRenouvelables;
        body.gainConsoEnergieRt = gainConsoEnergieRt;
        body.structureBat = structureBat;
        body.typeProtectionSolaire = typeProtectionSolaire;
        body.typeVentilation = typeVentilation;
        body.acousticien = acousticien;
        body.dispositifBio = dispositifBio;
        body.localoperateur = localoperateur;
        body.reseauinfotechnique = reseauinfotechnique;
        body.gtbouverteproprio = gtbouverteproprio;
        body.respectRgpd = respectRgpd;
        body.managementSmart = managementSmart;
        body.adductionTelecom = adductionTelecom;
        body.wifiPublic = wifiPublic;
        body.raccordementOperateur = raccordementOperateur;
        body.normespmr = normespmr;
        body.scoreConnect = Math.round(calcScoreConnect({
            gestionTechniqueBat,
            localoperateur: values.localoperateur,
            reseauinfotechnique: values.reseauinfotechnique,
            gtbouverteproprio: values.gtbouverteproprio,
            respectRgpd: values.respectRgpd,
            managementSmart: values.managementSmart,
            adductionTelecom: values.adductionTelecom,
            wifiPublic: values.wifiPublic,
            raccordementOperateur: values.raccordementOperateur,
            normespmr: values.normespmr
        }));
        body.scoreBim = Math.round(calcScoreBim({
            competencebim,
            maquettebim,
            objectifsBimConception,
            objectifsBimExecution,
            objectifsBimExploitation
        }));
        body.scoreEnvironnement = Math.round(calcScoreEnvironment({
            recupEauPluie,
            elementsProjetEnvironement,
            energiesRenouvelables,
            gainConsoEnergieRt,
            structureBat,
            typeProtectionSolaire,
            typeVentilation,
            acousticien,
            dispositifBio
        }));
        body.scoreFonctionnel = Math.round(calcScoreFonctionnel({
            typologie,
            postestravail,
            effectifs,
            plandeplacement,
            demarcherse,
            typeErp,
            categorieErp,
            normespmr,
            surface,
        }));
        //  (montant partiel / montant total) x 100
        fetch(`${process.env.REACT_APP_API_URL}/dossiers`, {
            method: 'POST',
            credentials: "include",
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(body)
        })
            .then(async (res) => {
                if (res.status === 200) {
                    res.json().then(result => {
                        dispatch({
                            type: USEFOLDER,
                            payload: {folderId: result.dossierId}
                        });
                        setredirectFolderResult(true);
                    })
                } else if (res.message) {
                    openNotification({
                        type: 'error',
                        message: res.message
                    });
                    setredirectHome(true);
                } else {
                    openNotification({
                        type: 'error',
                        message: "Une erreur s'est produite"
                    });
                    setredirectHome(true);
                }
            });
    };

    const onFinishThirdStep = values => {
        if (values.objectifsBimConception) {
            setobjectifsBimConception(values.objectifsBimConception)
        }
        if (values.objectifsBimExecution) {
            setobjectifsBimExecution(values.objectifsBimExecution)
        }
        if (values.objectifsBimExploitation) {
            setobjectifsBimExploitation(values.objectifsBimExploitation)
        }
        setrecupEauPluie(values.recupEauPluie);
        setelementsProjetEnvironement(values.elementsProjetEnvironement);
        setgestionTechniqueBat(values.gestionTechniqueBat);
        setenergiesRenouvelables(values.energiesRenouvelables);
        setgainConsoEnergieRt(values.gainConsoEnergieRt);
        setstructureBat(values.structureBat);
        settypeProtectionSolaire(values.typeProtectionSolaire);
        settypeVentilation(values.typeVentilation);
        setacousticien(values.acousticien);
        setdispositifBio(values.dispositifBio);
        changeStep(4);
    };

    const onFinishSecondStep = values => {
        if (values.competencebim) {
            setcompetencebim(values.competencebim);
        }
        if (values.maquettebim) {
            setmaquettebim(values.maquettebim);
        }
        if (values.postestravail) {
            setpostestravail(values.postestravail);
        }
        if (values.effectifs) {
            seteffectifs(values.effectifs);
        }
        if (values.plandeplacement) {
            setplandeplacement(values.plandeplacement);
        }
        if (values.demarcherse) {
            setdemarcherse(values.demarcherse);
        }
        if (values.categorieErp) {
            setcategorieErp(values.categorieErp);
        }
        if (values.typeErp) {
            settypeErp(values.typeErp);
        }
        changeStep(3);
    };

    const onFinishFirstStep = values => {
        setfolderName(values.name);
        setstatus(values.status);
        setadresse(values.address);
        setusager(values.usager);
        setsurface(values.surface);
        settypologie(values.typologie);
        setphase(values.phase);
        setclassement(values.classement);
        setneufreno(values.neufreno);
        fetch(`${process.env.REACT_APP_API_URL}/dossiers/findOne/${values.name}`, {
            method: 'GET',
            credentials: "include",
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        })
            .then(async (res) => {
                if (res.status === 300) {
                    changeStep(2);
                } else if (res.status === 200) {
                    openNotification({
                        type: 'warning',
                        message: "Ce nom de dossier est déjà utilisé"
                    });
                } else {
                    openNotification({
                        type: 'error',
                        message: "Une erreur s'est produite"
                    });
                    setredirectHome(true);
                }
            });

    };

    const changeStep = (step) => {
        switch (step) {
            case 1:
                setcurentStep(step);
                setStepTitle("Informations générales");
                break;
            case 2:
                setcurentStep(step);
                setStepTitle("Etape 2");
                break;
            case 3:
                setcurentStep(step);
                setStepTitle("Etape 3");
                break;
            case 4:
                setcurentStep(step);
                setStepTitle("Etape 4");
                break;
        }
    };

    const validQuestion = (question) => {
        if (question === 3.1) {
            return (phase === "Projet" || phase === "Faisabilité" || phase === "Programme" || phase === "Etudes") && competencebim === "Oui";
        } else if (question === 3.2) {
            return (phase === "Projet" || phase === "Faisabilité" || phase === "Programme" || phase === "Etudes" || phase === "Travaux") && competencebim === "Oui";
        } else if (question === 3.3) {
            return (phase === "Etudes" || phase === "Travaux") && competencebim === "Oui";
        }
    };

    if (redirectHome) {
        return (<Redirect to='/home'/>)
    } else if (redirectFolderResult) {
        return (<Redirect to='/folderResult'/>)
    } else {
        return (
            <div className={classes.container}>
                <div className={classes.subContainer}>
                    <div className={classes.headerContainer}>
                        <Link to={"/home"}>
                            <IconButton style={{
                                backgroundColor: '#99A0AB',
                                height: 35,
                                width: 35,
                                left: 15,
                                top: 15
                            }}>
                                <ArrowBackIosIcon className={classes.backIcon}/>
                            </IconButton>
                        </Link>
                        <div className={classes.displaySteps}>
                            Etape : {curentStep}/{totalStep}
                        </div>
                    </div>
                    <div className={classes.formContainer}>
                        <h2 style={{fontWeight: 'bold'}}>{StepTitle}</h2>
                        {curentStep === 1 &&
                        <Form
                            name={"new_folder"}
                            onFinish={onFinishFirstStep}
                        >
                            <Form.Item initialValue={folderName} name="name" style={{fontWeight: 'bold'}}
                                       label="Saisir le nom du dossier"
                                       rules={[{required: true, message: 'Obligatoire'}]}>
                                <Input/>
                            </Form.Item>

                            <Form.Item initialValue={status} name={"status"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}
                                       label={"Quel est votre statut ? "}>
                                <Radio.Group>
                                    <Radio value={"Acteur Public"}>Acteur Public</Radio>
                                    <Radio value={"Acteur Privé"}>Acteur Privé</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item initialValue={adresse} name="address" style={{fontWeight: 'bold'}}
                                       label="Quelle est l'adresse de votre bâtiment ?"
                                       rules={[{required: true, message: 'Obligatoire'}]}>
                                <Input/>
                            </Form.Item>

                            <Form.Item initialValue={usager} name={"usager"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}
                                       label={"Qui est le futur usager du projet ?"}>
                                <Radio.Group>
                                    <Radio value={"Vous-même"}>Vous-même</Radio>
                                    <Radio value={"Un acquéreur"}>Un acquéreur</Radio>
                                    <Radio value={"Un locataire"}>Un locataire</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item initialValue={surface} name="surface" style={{fontWeight: 'bold'}}
                                       label="Quelle est la surface (SDP) de votre bâtiment/projet ?"
                                       rules={[{required: true, message: 'Obligatoire'}]}>
                                <Form.Item name="surface" noStyle>
                                    <InputNumber min={1} max={100000}/>
                                </Form.Item>
                                <span className="ant-form-text"> m²</span>
                            </Form.Item>

                            <Form.Item initialValue={typologie} name="typologie" style={{fontWeight: 'bold'}}
                                       label="Quel est la typologie de votre projet ?"
                                       rules={[{required: true, message: 'Obligatoire'}]}>
                                <Select
                                    placeholder="Quel est la typologie de votre projet ?"
                                    allowClear
                                >
                                    <Option value="Bureaux">Bureaux</Option>
                                    <Option value="Industrie">Industrie</Option>
                                    <Option value="Logement">Logement</Option>
                                    <Option value="Hospitalier">Hospitalier</Option>
                                    <Option value="Commerce">Commerce</Option>
                                    <Option value="Enseignement">Enseignement</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item initialValue={phase} name="phase" style={{fontWeight: 'bold'}}
                                       label="Quelle est la phase en cours ?"
                                       rules={[{required: true, message: 'Obligatoire'}]}>
                                <Select
                                    placeholder="Quelle est la phase en cours ?"
                                    allowClear
                                >
                                    <Option value="Projet">Projet</Option>
                                    <Option value="Faisabilité">Faisabilité</Option>
                                    <Option value="Programme">Programme</Option>
                                    <Option value="Etudes">Etudes</Option>
                                    <Option value="Travaux">Travaux</Option>
                                    <Option value="GPA">GPA</Option>
                                    <Option value="Exploitation">Exploitation</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item initialValue={classement} name={"classement"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}
                                       label={"Quel est le classement de votre bâtiment ? ERT, ERP …"}>
                                <Radio.Group>
                                    <Radio value={"ERT"}>ERT</Radio>
                                    <Radio value={"ERP"}>ERP</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item initialValue={neufreno} name={"neufreno"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}
                                       label={"Votre projet concerne-t-il un bâtiment neuf ou une rénovation ?"}>
                                <Radio.Group>
                                    <Radio value={"Neuf"}>Neuf</Radio>
                                    <Radio value={"Rénovation"}>Rénovation</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item>
                                <Button className={classes.submitButton} htmlType="submit">
                                    Etape suivante
                                </Button>
                            </Form.Item>
                        </Form>}
                        {curentStep === 2 &&
                        <Form
                            name={"new_folder_second_step"}
                            onFinish={onFinishSecondStep}
                        >
                            {phase !== "Exploitation" &&
                            <Form.Item initialValue={competencebim} name={"competencebim"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}
                                       label={"Vos interlocuteurs sur le projet ont-ils la compétence dans le BIM ?"}>
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>}

                            {phase !== "Exploitation" &&
                            <Form.Item initialValue={maquettebim} name={"maquettebim"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}
                                       label={"Une maquette BIM accompagne-t-elle votre projet ?"}>
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>}

                            {classement === "ERT" && typologie === "Bureaux" &&
                            <Form.Item initialValue={postestravail} name="postestravail"
                                       style={{fontWeight: 'bold'}}
                                       label="Combien de postes de travail existe-t-il au sein de votre bâtiment ?">
                                <span className={classes.QuestionTips}>Saisir 0 si vous ne savez pas</span>
                                <Form.Item name="postestravail" noStyle>
                                    <InputNumber min={0} max={100000}/>
                                </Form.Item>
                            </Form.Item>}

                            {classement === "ERT" &&
                            <div>
                                <Form.Item initialValue={effectifs} name="effectifs" style={{fontWeight: 'bold'}}
                                           label="Combien d'effectifs occupent votre bâtiment ?">
                                    <span className={classes.QuestionTips}>Saisir 0 si vous ne savez pas</span>
                                    <Form.Item name="effectifs" noStyle>
                                        <InputNumber min={0} max={100000}/>
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item initialValue={plandeplacement} name={"plandeplacement"}
                                           rules={[{required: true, message: 'Obligatoire'}]}
                                           style={{fontWeight: 'bold'}}
                                           label={"Disposez vous d'un Plan de Déplacement D'entreprise ?"}>
                                    <Radio.Group>
                                        <Radio value={"Oui"}>Oui</Radio>
                                        <Radio value={"Non"}>Non</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item initialValue={demarcherse} name={"demarcherse"}
                                           rules={[{required: true, message: 'Obligatoire'}]}
                                           style={{fontWeight: 'bold'}}
                                           label={"Etes vous engagé dans une démarche RSE ?"}>
                                    <Radio.Group>
                                        <Radio value={"Oui"}>Oui</Radio>
                                        <Radio value={"Non"}>Non</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>}

                            {classement === "ERP" &&
                            <div>
                                <Form.Item initialValue={typeErp} name="typeErp" style={{fontWeight: 'bold'}}
                                           label="Quel est le type de votre ERP ?"
                                           rules={[{required: true, message: 'Obligatoire'}]}>
                                    <Select
                                        placeholder="Quel est le de votre ERP ?"
                                        allowClear
                                    >
                                        <Option value="Ne sais pas">Ne sais pas</Option>
                                        <Option value="J">J</Option>
                                        <Option value="L">L</Option>
                                        <Option value="M">M</Option>
                                        <Option value="N">N</Option>
                                        <Option value="O">O</Option>
                                        <Option value="P">P</Option>
                                        <Option value="R">R</Option>
                                        <Option value="S">S</Option>
                                        <Option value="T">T</Option>
                                        <Option value="U">U</Option>
                                        <Option value="V">V</Option>
                                        <Option value="W">W</Option>
                                        <Option value="X">X</Option>
                                        <Option value="Y">Y</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={categorieErp} name="categorieErp" style={{fontWeight: 'bold'}}
                                           label="Quelle est la catégorie de votre ERP ?"
                                           rules={[{required: true, message: 'Obligatoire'}]}>
                                    <Select
                                        placeholder="Quelle est la catégorie de votre ERP ?"
                                        allowClear
                                    >
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                    </Select>
                                </Form.Item>
                            </div>}

                            <div className={classes.buttonContainer}>
                                <Button className={classes.submitButton} style={{marginRight: 10}}
                                        onClick={() => changeStep(1)}>Etape
                                    précédente</Button>
                                <Form.Item className={classes.submitRight}>
                                    <Button className={classes.submitButton} htmlType="submit">
                                        Etape suivante
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>}

                        {curentStep === 3 &&
                        <Form
                            name={"new_folder_third_step"}
                            onFinish={onFinishThirdStep}
                        >
                            {validQuestion(3.1) &&
                            <div>
                                <div className={"ant-col ant-form-item"}
                                     style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                    <label className={"ant-form-item-required"} style={{height: "auto"}}>Quels sont vos
                                        objectifs du BIM en conception ?</label>
                                </div>
                                <Form.Item initialValue={objectifsBimConception}
                                           name="objectifsBimConception"
                                           style={{fontWeight: 'bold'}}
                                           rules={[{required: true, message: 'Obligatoire'}]}
                                    //label="Quels sont vos objectifs du BIM en conception ?"
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Quels sont vos objectifs du BIM en conception ?"
                                        allowClear
                                    >
                                        <Option value="Communication">Communication</Option>
                                        <Option value="Visualisation">Visualisation</Option>
                                        <Option value="Anticipation">Anticipation</Option>
                                        <Option value="Suivi de l'adéquation avec le programme">Suivi de l'adéquation
                                            avec
                                            le
                                            programme</Option>
                                        <Option value="Optimisation ACT">Optimisation ACT</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            }

                            {validQuestion(3.2) &&
                            <div>
                                <div className={"ant-col ant-form-item"}
                                     style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                    <label className={"ant-form-item-required"} style={{height: "auto"}}>Quels sont vos
                                        objectifs du BIM en execution ?</label>
                                </div>
                                <Form.Item initialValue={objectifsBimExecution}
                                           name="objectifsBimExecution"
                                           style={{fontWeight: 'bold'}}
                                           rules={[{required: true, message: 'Obligatoire'}]}
                                    //label="Quels sont vos objectifs du BIM en execution ?"
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Quels sont vos objectifs du BIM en execution ?"
                                        allowClear
                                    >
                                        <Option value="Réduction des aléas">Réduction des aléas</Option>
                                        <Option value="Réduction du temps de travaux">Réduction du temps de
                                            travaux</Option>
                                        <Option value="Optimisation des OPR">Optimisation des OPR</Option>
                                        <Option value="Avoir un DOE numérique">Avoir un DOE numérique</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            }

                            {validQuestion(3.3) &&
                            <div>
                                <div className={"ant-col ant-form-item"}
                                     style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                    <label className={"ant-form-item-required"} style={{height: "auto"}}>Quels sont vos
                                        objectfs en BIM exploitation ?</label>
                                </div>
                                <Form.Item initialValue={objectifsBimExploitation} name="objectifsBimExploitation"
                                           style={{fontWeight: 'bold'}}
                                           rules={[{required: true, message: 'Obligatoire'}]}
                                    //label="Quels sont vos objectfs en BIM exploitation ?"
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Quels sont vos objectfs en BIM exploitation ?"
                                        allowClear
                                    >
                                        <Option value="Gestion des espaces">Gestion des espaces</Option>
                                        <Option value="Contrôles et optimisation énergétique">Contrôles et optimisation
                                            énergétique</Option>
                                        <Option value="Entretien du bâtiment">Entretien du bâtiment</Option>
                                        <Option value="Contrôle d'accès/sécurité">Contrôle d'accès/sécurité</Option>
                                        <Option value="Gestion des sinistres">Gestion des sinistres</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            }

                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>De la récupération
                                    d'eau de pluie est elle présente/prévue sur le projet ?</label>
                            </div>
                            <Form.Item initialValue={recupEauPluie}
                                       name="recupEauPluie"
                                       style={{fontWeight: 'bold'}}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label="De la récupération d'eau de pluie est elle présente/prévue sur le projet ?"
                            >
                                <Select
                                    placeholder="De la récupération d'eau de pluie est elle présente/prévue sur le projet ?"
                                    allowClear
                                >
                                    <Option value="Oui, pour l'arrosage">Oui, pour l'arrosage</Option>
                                    <Option value="Oui, pour les sanitaires">Oui, pour les sanitaires</Option>
                                    <Option value="Oui, pour l'arrosage et les sanitaires">Oui, pour l'arrosage et les
                                        sanitaires</Option>
                                    <Option value="Non">Non</Option>
                                </Select>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Quels éléments
                                    parmis
                                    les suivants font parti de votre projet ou y sont prévus ?</label>
                            </div>
                            <Form.Item
                                initialValue={elementsProjetEnvironement}
                                name="elementsProjetEnvironement"
                                style={{fontWeight: 'bold'}}
                                rules={[{required: true, message: 'Obligatoire'}]}
                                //label="Quels éléments parmis les suivants font parti de votre projet ou y sont prévus ?"
                            >
                                <Select mode="multiple"
                                        placeholder="Quels éléments parmis les suivants font parti de votre projet ou y sont prévus ?">
                                    <Option value="Emplacement pour cycles">Emplacement pour cycles</Option>
                                    <Option value="Douches">Douches</Option>
                                    <Option value="Vestiaires">Vestiaires</Option>
                                    <Option value="Bornes de recharge pour VE">Bornes de recharge pour VE</Option>
                                    <Option value="Places dédiés au covoiturage">Places dédiés au covoiturage</Option>
                                    <Option value="Plateforme de covoiturage">Plateforme de covoiturage</Option>
                                </Select>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Votre projet
                                    dispose t
                                    il d'un système de gestion technique du bâtiment (GTC/GTB) ?</label>
                            </div>
                            <Form.Item initialValue={gestionTechniqueBat} name={"gestionTechniqueBat"}
                                       style={{fontWeight: 'bold'}}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Votre projet dispose t il d'un système de gestion technique du bâtiment (GTC/GTB) ?"}
                            >
                                {/*<div>
                                <div className={"ant-col ant-form-item-label"}>
                                    <label className={"ant-form-item-required"} style={{height: "auto"}} >Votre projet dispose t il d'un système de gestion technique du bâtiment (GTC/GTB) ?</label>
                                </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                                {/*</div>*/}
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Quelle(s)
                                    énergie(s)
                                    renouvelable(s) est(sont) présente(s) sur le projet ?</label>
                            </div>
                            <Form.Item
                                initialValue={energiesRenouvelables}
                                name="energiesRenouvelables"
                                style={{fontWeight: 'bold'}}
                                rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Quelle(s) énergie(s) renouvelable(s) est(sont) présente(s) sur le projet ?"}
                            >
                                {/*<div>
                                <div className={"ant-col ant-form-item-label"}>
                                    <label className={"ant-form-item-required"} style={{height: "auto"}} >Quelle(s) énergie(s) renouvelable(s) est(sont) présente(s) sur le projet ?</label>
                                </div>*/}
                                <Select mode="multiple"
                                        placeholder="Quelle(s) énergie(s) renouvelable(s) est(sont) présente(s) sur le projet ?">
                                    <Option value="Panneaux photovoltaique">Panneaux photovoltaique</Option>
                                    <Option value="Panneaux solaires (ECS)">Panneaux solaires (ECS)</Option>
                                    <Option value="Géothermie">Géothermie</Option>
                                    <Option value="Eolienne urbaine">Eolienne urbaine</Option>
                                    <Option value="Chauferie bois">Chaufferie bois</Option>
                                    <Option value="Aucune">Aucune</Option>
                                </Select>
                                {/*</div>*/}
                            </Form.Item>

                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Quel est le gain
                                    visé ou atteint par rapport à la consommation conventionnelle en énergie primaire de
                                    la RT ?</label>
                            </div>
                            <Form.Item initialValue={gainConsoEnergieRt}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       name="gainConsoEnergieRt"
                                       style={{fontWeight: 'bold'}}
                            >
                                {/**/}
                                <Select
                                    placeholder="Quel est le gain visé ou atteint par rapport à la consommation conventionnelle en énergie primaire de la RT ?"
                                    allowClear
                                >
                                    <Option value="Moins de 10%">Moins de 10%</Option>
                                    <Option value="Entre 10 et 20%">Entre 10 et 20%</Option>
                                    <Option value="Entre 20 et 30%">Entre 20 et 30%</Option>
                                    <Option value="Entre 30 et 40%">Entre 30 et 40%</Option>
                                    <Option value="Plus de 40%">Plus de 40%</Option>
                                    <Option value="Bâtiment passif">Bâtiment passif</Option>
                                    <Option value="Bâtiment à énergie positive">Bâtiment à énergie positive</Option>
                                </Select>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Quelle est la
                                    structure
                                    principale de votre/vos batiments ?</label>
                            </div>
                            <Form.Item initialValue={structureBat}
                                       name="structureBat"
                                       style={{fontWeight: 'bold'}}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                            >
                                <Select
                                    placeholder="Quelle est la structure principale de votre/vos batiments ?"
                                    allowClear
                                >
                                    <Option value="Béton">Béton</Option>
                                    <Option value="Béton bas carbone">Béton bas carbone</Option>
                                    <Option value="Maçonnerie">Maçonnerie</Option>
                                    <Option value="Acier">Acier</Option>
                                    <Option value="CLT">CLT</Option>
                                    <Option value="Ossature bois">Ossature bois</Option>
                                    <Option value="Mix bois/béton">Mix bois/béton</Option>
                                    <Option value="Mix acier/béton">Mix acier/béton</Option>
                                </Select>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Quel type de
                                    protection
                                    solaire est présente sur le projet ?</label>
                            </div>
                            <Form.Item
                                initialValue={typeProtectionSolaire}
                                name="typeProtectionSolaire"
                                style={{fontWeight: 'bold'}}
                                rules={[{required: true, message: 'Obligatoire'}]}
                            >
                                <Select mode="multiple"
                                        placeholder="Quel type de protection solaire est présente sur le projet ?">
                                    <Option value="Aucune">Aucune</Option>
                                    <Option value="Store intérieurs">Store intérieurs</Option>
                                    <Option value="Vitrage à faible facteur solaire">Vitrage à faible facteur
                                        solaire</Option>
                                    <Option value="Brise soleil fixe (façade sud)">Brise soleil fixe (façade
                                        sud)</Option>
                                    <Option value="Brise soleil orientable (façade est/ouest)">Brise soleil orientable
                                        (façade est/ouest)</Option>
                                    <Option value="Store toile extérieur">Store toile extérieur</Option>
                                    <Option value="Casquettes">Casquettes</Option>
                                </Select>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Quel est le type de
                                    ventilation présente sur le projet ?</label>
                            </div>
                            <Form.Item initialValue={typeVentilation} name={"typeVentilation"}
                                       style={{fontWeight: 'bold'}}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                            >
                                <Radio.Group>
                                    <Radio value={"Simple flux"}>Simple flux</Radio>
                                    <Radio value={"Double flux"}>Double flux</Radio>
                                </Radio.Group>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Un acousticien est
                                    il
                                    présent sur le projet ?</label>
                            </div>
                            <Form.Item initialValue={acousticien} name={"acousticien"}
                                       style={{fontWeight: 'bold'}}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Votre projet dispose t il d'un système de gestion technique du bâtiment (GTC/GTB) ?"}
                            >
                                {/*<div>
                                <div className={"ant-col ant-form-item-label"}>
                                    <label className={"ant-form-item-required"} style={{height: "auto"}} >Votre projet dispose t il d'un système de gestion technique du bâtiment (GTC/GTB) ?</label>
                                </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                                {/*</div>*/}
                            </Form.Item>

                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Quel(s)
                                    dispositif(s)
                                    en faveur de la biodiversité sont mis en place ou prévus sur le projet ?</label>
                            </div>
                            <Form.Item
                                initialValue={dispositifBio}
                                name="dispositifBio"
                                style={{fontWeight: 'bold'}}
                                rules={[{required: true, message: 'Obligatoire'}]}
                            >
                                <Select mode="multiple"
                                        placeholder="Quel(s) dispositif(s) en faveur de la biodiversité sont mis en place ou prévus sur le projet ?">
                                    <Option value="Ruches">Ruches</Option>
                                    <Option value="Nichoirs à oiseaux">Nichoirs à oiseaux</Option>
                                    <Option value="Gites à insectes">Gites à insectes</Option>
                                    <Option value="Toiture végétalisé extensive">Toiture végétalisé extensive</Option>
                                    <Option value="Toiture végétalisé semi-intensive">Toiture végétalisé
                                        semi-intensive</Option>
                                    <Option value="Toiture végétalisé intensive">Toiture végétalisé intensive</Option>
                                    <Option value="Réduction de la pollution lumineuse">Réduction de la pollution
                                        lumineuse</Option>
                                    <Option value="Potager urbain">Potager urbain</Option>
                                    <Option value="Sensibilisation (affichage, actions,..)">Sensibilisation (affichage,
                                        actions,..)</Option>
                                </Select>
                            </Form.Item>


                            <div className={classes.buttonContainer}>
                                <Button className={classes.submitButton} onClick={() => changeStep(2)}>Etape
                                    précédente</Button>
                                <Form.Item className={classes.submitRight}>
                                    <Button className={classes.submitButton} htmlType="submit">
                                        Etape suivante
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>}
                        {curentStep === 4 &&
                        <Form
                            name={"new_folder_fourth_step"}
                            onFinish={onFinishFourthStep}
                        >
                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Est-ce que votre
                                    projet
                                    prévoit un local opérateur ?</label>
                            </div>
                            <Form.Item initialValue={localoperateur} name={"localoperateur"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}>
                                {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Est-ce que votre projet  prévoit un local opérateur ?</label>
                            </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Avez-vous prévu un
                                    réseau informatique technique sur votre projet (compris éléments actifs)</label>
                            </div>
                            <Form.Item initialValue={reseauinfotechnique} name={"reseauinfotechnique"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Avez-vous prévu un réseau informatique technique sur votre projet (compris éléments actifs)"}
                                       style={{fontWeight: 'bold'}}>
                                {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Avez-vous prévu un réseau informatique technique sur votre projet (compris éléments actifs)</label>
                            </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {gestionTechniqueBat === "Oui" &&
                            <div>
                                <div className={"ant-col ant-form-item"}
                                     style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                    <label className={"ant-form-item-required"} style={{height: "auto"}}>Votre GTB
                                        est-elle ouverte ou propriétaire</label>
                                </div>
                                <Form.Item initialValue={gtbouverteproprio} name={"gtbouverteproprio"}
                                           rules={[{required: true, message: 'Obligatoire'}]}
                                    //label={"Votre GTB est-elle ouverte 2 ou propriétaire"}
                                           style={{fontWeight: 'bold'}}>
                                    {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Votre GTB est-elle ouverte 2 ou propriétaire</label>
                            </div>*/}
                                    <Radio.Group>
                                        <Radio value={"Oui"}>Oui</Radio>
                                        <Radio value={"Non"}>Non</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            }


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Avez-vous pris en
                                    compte le respect de la RGPD ?</label>
                            </div>
                            <Form.Item initialValue={respectRgpd} name={"respectRgpd"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Avez-vous pris en compte le respect de la RGPD ?"}
                                       style={{fontWeight: 'bold'}}>
                                {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Avez-vous pris en compte le respect de la RGPD ?</label>
                            </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Avez-vous un
                                    management/lot SMART de Projet ?</label>
                            </div>
                            <Form.Item initialValue={managementSmart} name={"managementSmart"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Avez-vous un management/lot SMART de Projet ?"}
                                       style={{fontWeight: 'bold'}}>
                                {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Avez-vous un management/lot SMART de Projet ?</label>
                            </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Votre projet
                                    possède -t-il une adduction Télécom ?</label>
                            </div>
                            <Form.Item initialValue={adductionTelecom} name={"adductionTelecom"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Votre projet possède -t-il une adduction Télécom ?"}
                                       style={{fontWeight: 'bold'}}>
                                {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Votre projet possède -t-il une adduction Télécom ?</label>
                            </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Avez-vous prévu un
                                    réseau wifi publique ?</label>
                            </div>
                            <Form.Item initialValue={wifiPublic} name={"wifiPublic"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Avez-vous prévu un réseau wifi publique ?"}
                                       style={{fontWeight: 'bold'}}>
                                {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Avez-vous prévu un réseau wifi publique ?</label>
                            </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>


                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Avez-vous 1 lettre
                                    d’engagement ou un devis d’étude de faisabilité de raccordement d’un réseau
                                    opérateur ?</label>
                            </div>
                            <Form.Item initialValue={raccordementOperateur} name={"raccordementOperateur"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                //label={"Avez-vous 1 lettre d’engagement ou un devis d’étude de faisabilité de raccordement d’un réseau opérateur ?"}
                                       style={{fontWeight: 'bold'}}>
                                {/*<div className={"ant-col ant-form-item-label"}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}} >Avez-vous 1 lettre d’engagement ou un devis d’étude de faisabilité de raccordement d’un réseau opérateur ?</label>
                            </div>*/}
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <div className={"ant-col ant-form-item"}
                                 style={{whiteSpace: 'initial', textAlign: 'left', fontWeight: 'bold'}}>
                                <label className={"ant-form-item-required"} style={{height: "auto"}}>Votre projet
                                    respecte-t-il les normes d'accès PMR ?</label>
                            </div>
                            <Form.Item initialValue={normespmr} name={"normespmr"}
                                       rules={[{required: true, message: 'Obligatoire'}]}
                                       style={{fontWeight: 'bold'}}>
                                <Radio.Group>
                                    <Radio value={"Oui"}>Oui</Radio>
                                    <Radio value={"Non"}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <div className={classes.buttonContainer}>
                                <Button className={classes.submitButton} onClick={() => changeStep(3)}>Etape
                                    précédente</Button>
                                <Form.Item className={classes.submitRight}>
                                    <Button className={classes.submitButton} htmlType="submit">
                                        Envoyer
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>}
                    </div>
                </div>
            </div>
        )
    }
};

export default BatForm;
