import React, {useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch} from "react-redux";
import style from './register_styles';
import {Input} from "antd";
import Button from "antd/es/button";
import {register} from "../../actions/auth";
import Form from "antd/es/form";
import LogoProjexBlanc from "../../images/LOGO_GLOBAL_PERF_BLANC.png";

const useStyles = createUseStyles(style);

const Register = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [redirect, setredirect] = useState(false);
    const [validCGU, setvalidCGU] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const validate = values => {
        if (values.password === values.secondpassword) {
            register(values.email.toLowerCase(), values.password, values.firstname, values.lastname, values.entreprise)(dispatch).then(() => {
                setredirect(true);
            });
        }
    };

    const handleClose = () => {
      setOpenModal(false);
    };

    const changeCheckBox = (e) => {
        setvalidCGU(e.target.checked);
    };

    const body = (
        <div className={classes.modalContainer}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse turpis ex, pulvinar pretium nulla ut, rutrum aliquam nisi. Aenean pharetra augue sit amet diam tristique, quis accumsan sapien dapibus. Aliquam eget ullamcorper elit. Cras imperdiet ex in augue euismod pretium. In nibh augue, commodo a hendrerit eget, accumsan at lacus. Curabitur non malesuada turpis, id venenatis augue. Duis ex orci, mollis quis convallis sit amet, porttitor non mauris. Quisque nec elementum ligula. Vivamus aliquam erat non erat porta vulputate. Donec mattis auctor lectus, et vestibulum nulla ullamcorper at. Curabitur tempor augue nisl, eget maximus orci ultricies a. Duis accumsan accumsan condimentum.

            Maecenas turpis quam, consequat vitae dui quis, volutpat faucibus arcu. Suspendisse sit amet nisl blandit, blandit tellus sed, sollicitudin felis. Nunc varius tempor pulvinar. Curabitur urna libero, imperdiet id rhoncus efficitur, pulvinar at lorem. Etiam tincidunt metus id tellus pharetra, vel ornare risus viverra. Nam suscipit eleifend efficitur. Nam vitae nibh eu felis gravida dignissim nec eu justo. Etiam mattis tellus est, a pellentesque eros consectetur id.
        </div>
    );

    /*
    *
    * Décommenter la checkbox & le modal
    * ajouter disabled={!validCGU} au bouton
    *
    * */

    if (redirect) {
        return (<Redirect to={"/login"}/>)
    } else {
        return (
            <div className={classes.container}>
                <div className={classes.formContainer}>
                    <span className={classes.loginTitle}>S'inscrire</span>
                    <Form
                        className={classes.form}
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={validate}
                    >
                        <Form.Item
                            name="email"
                            rules={[{required: true, message: 'Veuillez indiquer votre email'}]}
                        >
                            <Input className={classes.formInput} type={"email"} placeholder={"email"}/>
                        </Form.Item>
                        <Form.Item
                            name="firstname"
                            rules={[{required: true, message: 'Veuillez indiquer votre prénom'}]}
                        >
                            <Input className={classes.formInput} type={"text"} placeholder={"prénom"}/>
                        </Form.Item>
                        <Form.Item
                            name="lastname"
                            rules={[{required: true, message: 'Veuillez indiquer votre nom'}]}
                        >
                            <Input className={classes.formInput} type={"text"} placeholder={"nom"}/>
                        </Form.Item>

                        <Form.Item
                            name="entreprise"
                            rules={[{required: true, message: 'Veuillez indiquer votre entreprise'}]}
                        >
                            <Input className={classes.formInput} type={"text"} placeholder={"Entreprise"}/>
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{required: true, message: 'Veuillez rentrer un mot de passe'}]}
                        >
                            <Input type={"password"} className={classes.formInput} placeholder={"mot de passe"}/>
                        </Form.Item>
                        <Form.Item
                            name="secondpassword"
                            rules={[{required: true, message: 'Veuillez confirmer votre mot de passe'}]}
                        >
                            <Input type={"password"} className={classes.formInput}
                                   placeholder={"confirmation de mot de passe"}/>
                        </Form.Item>
                        {/*<div className={classes.checkBoxContainer}>
                            <Checkbox onChange={e => changeCheckBox(e)} checked={validCGU} className={classes.checkBox}>J'accepte les conditions d'utilisation</Checkbox>
                            <VisibilityIcon onClick={() => setOpenModal(true)} className={classes.visibilityIcon}/>
                        </div>*/}
                        <Form.Item>
                            <Button className={classes.button} htmlType="submit">
                                Créer le compte
                            </Button>
                            <Link to={"/"}>
                                <p className={classes.SubText}>Connexion</p>
                            </Link>
                        </Form.Item>

                    </Form>
                </div>

                <img src={LogoProjexBlanc} className={classes.logo}/>
                {/*<Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>*/}
            </div>
        )
    }
};

export default Register;
