import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import Cookies from 'universal-cookie';

import {LOGIN, LOGOUT} from "../../types";
import {openNotification} from "../../actions/notifications";

const AuthWrapper = ({children}) => {
    const cookies = new Cookies();
    const {isAuth} = useSelector(store => store.user);
    const dispatch = useDispatch();
   if (cookies.get("sess")) {
        return (children);
    } else {
        return <Redirect to={'/'} />
    }
};

export default AuthWrapper;