export default {
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    fillStar: {
        color: 'black'
    },
    emptyStar: {
        color: 'black'
    }
}
