export default {
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    fillStar: {
        width: "auto",
        height: 20,
        marginLeft: 5
    },
    emptyStar: {
        width: "auto",
        height: 20,
        marginLeft: 5
    }
}
