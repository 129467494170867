import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import style from './critereResult_style';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {openNotification} from "../../actions/notifications";
import 'react-svg-radar-chart/build/css/index.css'
import StarScore from "../../components/starScore/starScore";
import LogoProjexBlanc from "../../images/LOGO-FINAL-DS-03_1024.png";
import Contact from "../contact/contact";
import {getPiste} from "../../actions/texts";

const useStyles = createUseStyles(style);

const CritereResult = (props) => {
    const classes = useStyles();
    const {id, displayCritere, stars} = useSelector(store => store.folder);
    const dispatch = useDispatch();
    const [displayContact, setdisplayContact] = useState(false);
    const [redirect, setredirect] = useState(false);
    const [dossierInfos, setdossierInfos] = useState({});
    const [definition, setdefinition] = useState();


    useEffect(() => {
        if (!id || !displayCritere) {
            setredirect(true);
        }

        if (id) {
            fetch(`${process.env.REACT_APP_API_URL}/dossiers/infos/${id}`, {
                method: 'GET',
                credentials: "include",
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            }).then(res => res.json())
                .then(res => {
                    if (res) {
                        setdossierInfos(res[0]);
                    }
                });
        }

        switch (displayCritere) {
            case "Environnement":
                setdefinition("Le thème \"Environnement\" vise à évaluer les mesures mise en place sur votre bâtiment ou projet au regard de l'envrionnement au sens large. En analysant par thèmatiques (Energie, Mobilité, Confort, Matériaux, Biodiversité, etc...), il s'agit d'appréhender l'impact ");
                break;
            case "Connectivité":
                setdefinition("Le thème \"Connectivité\" vise à évaluer les mesures mise en place sur votre bâtiment ou projet au regard d'un bâtiment intelligent. Il s'agit d'appréhender l'impact de l'interropérabilité de vos systèmes et de savoir si votre projet sera évolutif techniquement dans le ");
                break;
            case "BIM":
                setdefinition("Le thème \"BIM\" vise à évaluer les mesures mises en place sur votre bâtiment au sens du BIM. Le critère détermine la facilité à mettre en place le BIM sur votre projet et son utilisation par les différents intervenants.");
                break;
            case "Fonctionnel":
                setdefinition("Le thème \"Fonctionnel\" vise à évaluer la fonctionnalité de votre bâtiment ou projet. En analysant les ratios entre surfaces et occupations, il s'agit d'appréhender l'optimisation de vos locaux et la notion de performance immobilière.");
                break;
        }
    }, []);

    if (redirect) {
        openNotification({
            type: 'warning',
            message: "Veuillez selectionner un dossier & un critère pour acceder à cette page"
        });
        return (<Redirect to={"/home"}/>);
    } else if (displayContact) {
        return (<Contact setdisplayContact={(param) => setdisplayContact(param)} />);
    } return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <div className={classes.headerContainer}>
                    <Link to={"/folderResult"}>
                        <IconButton style={{
                            backgroundColor: '#99A0AB',
                            height: 35,
                            width: 35,
                            left: 15,
                            top: 15
                        }}>
                            <ArrowBackIosIcon className={classes.backIcon}/>
                        </IconButton>
                    </Link>
                    <div className={classes.textLogoContainer}>
                        <div className={classes.headerTitleContainer}>
                            <span className={classes.batimentName}>{dossierInfos.Nom}</span>
                            <span>{dossierInfos.Adresse}</span>
                        </div>
                    </div>
                </div>
                <div className={classes.whiteContainer}>
                    <div className={classes.critereRow}>
                        <span className={classes.critereTitle}>{displayCritere}</span>
                        <div>
                            <StarScore score={stars} total={5}/>
                        </div>
                    </div>
                    <h3 className={classes.definitionTitle}>Définition du critère</h3>
                    <p className={classes.paragraph}>{definition}</p>
                    <h3 className={classes.definitionTitle}>Pistes d'amélioration</h3>
                    <p className={classes.paragraph}>{getPiste(displayCritere, stars)}</p>
                </div>
                <div className={classes.logoContainer}>
                    <img onClick={() => setdisplayContact(true)} src={LogoProjexBlanc} className={classes.logoBottom}/>
                </div>
            </div>
        </div>
    )

};

export default CritereResult;
