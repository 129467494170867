export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        color: 'white',
        margin: 'auto',
        top: 0,
        left: 0,
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        animation: '15s ease 0s infinite $backgroundAnime',
    },
    '@keyframes backgroundAnime': {
        '0%':{
            backgroundPosition: '0% 50%',
        },
        '50%':{
            backgroundPosition: '100% 50%',
        },
        '100%':{
            backgroundPosition: '0% 50%',
        }
    },
    title:{
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
        marginTop: 15
    },
    tabsContainer:{
        display: 'flex',
        justifyContent: 'center',
    },
    tab:{
        color: 'white',
        fontSize: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer'
    },
    selectedTab:{
        color: '#1684AF',
        fontSize: 40
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    backIcon: {
        color: 'white'
    },
    subContainer: {
        width: "100%",
        margin: 'auto'
    },
    '@media (min-width: 800px)': {
        subContainer: {
            width: '70%'
        }
    },
    '@media (min-width: 1000px)': {
        subContainer: {
            width: '50%'
        }
    },
    '@media (min-width: 1300px)': {
        subContainer: {
            width: '30%'
        }
    },
    tutorial: {
        backgroundColor: 'white',
        color: 'black',
        borderRadius: 15,
        marginTop: 35,
        marginBottom: 35,
        overflow: 'auto',
        marginRight: 10,
        marginLeft: 10,
        animation: '0.7s ease-out 0s 1 $slideInFromLeft',
        position: 'relative',
    },
    tutorialTitle:{
        fontSize: '20px',
        fontWeight: "900",
    },
    tutorialText:{
        fontWeight: "bold",
        fontSize: "15px",
        marginLeft: 10,
        marginRight: 10
    },
    tutorialImg:{
        padding: 10,
        width: "auto",
        height: "600px",
    },
    '@keyframes slideInFromLeft': {
        '0%':{
            opacity:0,
            transform: 'translateX(-100vw)',
        },
        '100%':{
            opacity:1,
            transform: 'translateX(0px)',
        }
    },
}