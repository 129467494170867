export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        margin: 'auto',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        animation: '15s ease 0s infinite $backgroundAnime'
    },
    '@keyframes backgroundAnime': {
        '0%':{
            backgroundPosition: '0% 50%',
        },
        '50%':{
            backgroundPosition: '100% 50%',
        },
        '100%':{
            backgroundPosition: '0% 50%',
        }
    },
    button: {
        borderRadius: 20,
        marginTop: 80,
        height: 50,
        fontSize: 20,
        fontWeight: "bold",
        color: '#001942'
    },
    SubText: {
        fontSize: 12,
        color: "white",
        marginTop: 5
    },
    inputContainer: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    formInput: {
        backgroundColor: "#ECECEC",
        borderRadius: 7
    },
    loginTitle: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 27,
        marginBottom: 20
    },
    formContainer: {
        width: "80%",
        margin: 'auto',
        marginTop: 80
    },
    form: {
        marginTop: 20
    },
    logo: {
        width: 250,
        marginTop: 35
    },
    checkBox: {
        color: 'white'
    },
    checkBoxContainer: {
        display: 'flex',
        margin: 'auto',
        width: 'max-content'
    },
    visibilityIcon: {
        color: 'white',
        cursor: 'pointer'
    },
    modalContainer: {
        color: '#001942',
        margin: 'auto',
        width: 'max-content',
        maxWidth: '40%',
        height: 'max-content',
        maxHeight: '60%',
        overflow: 'scroll',
        backgroundColor: '#E8F0FE',
        padding: 15,
        borderRadius: 10,
        marginTop: '10%'
    },
    '@media (max-height: 740px)': {
        button: {
            marginTop: 50
        },
        formContainer: {
            marginTop: 50
        },
        logo: {
            marginTop: 15
        }
    },
    '@media (max-height: 900px)': {
        button: {
            marginTop: 30
        },
        logo: {
            marginTop: 0
        }
    },
    '@media (max-height: 675px)': {
        button: {
            marginTop: 40
        },
        formContainer: {
            marginTop: 40
        },
        logo: {
            marginTop: 15
        }
    },
    '@media (max-width: 400px)': {
        button: {
            fontSize: 14
        }
    },
    '@media (max-width: 1000px)': {
        modalContainer: {
            maxWidth: '60%'
        }
    },
    '@media (max-width: 950px)': {
        checkBox: {
            fontSize: '10px !important'
        },
        visibilityIcon: {
            fontSize: '17px !important'
        }
    },
    '@media (max-width: 500px)': {
        modalContainer: {
            maxWidth: '80%',
            marginTop: '20%'
        },
        checkBoxContainer: {
            maxWidth: '80%'
        }
    },
    '@media (min-width: 700px)': {
        formContainer: {
            width: '50%'
        },
        SubText: {
            fontSize: 15
        }
    },
    '@media (min-width: 1000px)': {
        formContainer: {
            width: '30%'
        }
    },
    '@media (min-width: 1400px)': {
        formContainer: {
            width: '20%'
        }
    }
}
