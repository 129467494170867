import {ADDTHEME1, ADDTHEME2, ADDTHEME3, ADDTHEME4, ADDTHEME5, FILLSTATE, RESETRSE} from "../types";
import {postRseResult} from "../actions/rse";

const initialState = {
    theme1: "",
    theme2: "",
    theme3: "",
    theme4: "",
    theme5: "",
};

const rse = (state = initialState, action) => {
    switch (action.type) {
        case ADDTHEME1:
            let theme1 = JSON.parse(JSON.stringify(action.payload));
            const returnState = {
                ...state,
                theme1: theme1
            }
            return(returnState);
        case ADDTHEME2:
            let theme2 = JSON.parse(JSON.stringify(action.payload));
            const returnState2 = {
                ...state,
                theme2: theme2
            }
            return(returnState2);
        case ADDTHEME3:
            let theme3 = JSON.parse(JSON.stringify(action.payload));
            const returnState3 = {
                ...state,
                theme3: theme3
            }
            return(returnState3);
        case ADDTHEME4:
            let theme4 = JSON.parse(JSON.stringify(action.payload));
            const returnState4 = {
                ...state,
                theme4: theme4
            }
            return(returnState4);
        case ADDTHEME5:
            let theme5 = JSON.parse(JSON.stringify(action.payload));
            const returnState5 = {
                ...state,
                theme5: theme5
            }
            return(returnState5);
        case FILLSTATE:
            let data = JSON.parse(JSON.stringify(action.payload));
            return(data);
        case RESETRSE:
            return initialState;
        default:
            return state
    }
};

export default rse;
