import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import style from './starScoreBim_style';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import FillIcon from '../../images/BimGP.png';
import EmptyIcon from '../../images/BimGP2.png';

const useStyles = createUseStyles(style);

const StarScoreBim = (props) => {
    const classes = useStyles();
    const [Stars, setStars] = useState([]);

    useEffect(() => {
        let tmpArray = [];
        for (let i = 1; i <= props.total; i++) {
            if (i <= props.score) {
                tmpArray.push(1);
            } else {
                tmpArray.push(0);
            }
        }
        setStars(tmpArray);
    }, []);

    return (
        <div className={classes.container}>
            {Stars.filter(s => s === 1).map((star, index) => (
                <img src={FillIcon} key={index} className={classes.fillStar}/>
            ))}
            {Stars.filter(s => s === 0).map((star, index) => (
                <img src={EmptyIcon} key={index} className={classes.emptyStar}/>
            ))}
        </div>
    )
};

export default StarScoreBim;
