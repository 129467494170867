export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        color: 'white',
        margin: 'auto',
        top: 0,
        left: 0,
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        animation: '15s ease 0s infinite $backgroundAnime',
    },
    '@keyframes backgroundAnime': {
        '0%':{
            backgroundPosition: '0% 50%',
        },
        '50%':{
            backgroundPosition: '100% 50%',
        },
        '100%':{
            backgroundPosition: '0% 50%',
        }
    },
    installButton:{
        float: 'right',
        width: '75px',
        height: '25px',
        marginTop: '16px',
        marginRight: '12px',
        backgroundColor: 'transparent',
        borderRadius: '5px',
        border: 'solid 2px white',
    },
    emptyFolderMessage: {
        marginTop: 20,
        animation: '2s linear 0s 1 $Appear',
    },
    logoutIcon: {
        color: 'white',
        marginBottom: 3
    },
    userName: {
        fontSize: 20,
        display: 'inline',
        fontWeight: 'bold'
    },
    subContainer: {
        width: "100%",
        margin: 'auto'
    },
    nameLogoutContainer: {
        marginTop: 50,
        width: 'fit-content',
        height: 35,
        marginLeft: 20,
        animation: '0.5s linear 0s 1 $AppearFast',
    },
    '@keyframes AppearFast': {
        '0%':{
            opacity:0,
        },
        '100%':{
            opacity:1,
        }
    },
    subTitle: {
        width: 'fit-content',
        marginLeft: 20,
        animation: '0.5s linear 0s 1 $AppearFast',
    },
    rseContainer: {
        backgroundColor: 'white',
        borderRadius: 15,
        marginTop: 35,
        marginBottom: 35,
        overflow: 'auto',
        marginRight: 10,
        marginLeft: 10,
        animation: '0.7s ease-out 0s 1 $slideInFromLeft',
    },
    '@keyframes slideInFromLeft': {
        '0%':{
            opacity:0,
            transform: 'translateX(-100vw)',
        },
        '100%':{
            opacity:1,
            transform: 'translateX(0px)',
        }
    },
    batContainer: {
        backgroundColor: 'white',
        borderRadius: 15,
        marginTop: 35,
        marginBottom: 35,
        overflow: 'auto',
        marginRight: 10,
        marginLeft: 10,
        animation: '2s linear 0s 1 $Appear',
    },
    '@keyframes Appear': {
        '0%':{
            opacity:0,
        },
        '50%':{
            opacity:0,
        },
        '100%':{
            opacity:1,
        }
    },
    addLogo: {
        display: 'inline',
        width: 31,
        animation: '20s linear 0s infinite $RotateButton',
    },
    '@keyframes RotateButton': {
        '0%':{
            transform: 'rotate(0)',
        },
        '23%':{
            transform: 'rotate(0)',
        },
        '25%':{
            transform: 'rotate(90deg)',
        },
        '48%':{
            transform: 'rotate(90deg)',
        },
        '50%':{
            transform: 'rotate(180deg)',
        },
        '73%':{
            transform: 'rotate(180deg)',
        },
        '75%':{
            transform: 'rotate(270deg)',
        },
        '98%':{
            transform: 'rotate(270deg)',
        },
        '100%':{
            transform: 'rotate(360deg)',
        }
    },
    addBatTitle: {
        fontWeight: 'bold',
        marginLeft: 15
    },
    addBatContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
        color: 'white',
        marginTop: 12,
        width: "60%",
        animation: '0.5s linear 0s 1 $AppearFast',
    },
    subContainerRse: {
        margin: 10,
        color: 'black',
        display: 'flex',
    },
    RemakeIcon: {
        color: "#001942",
        fontSize: '40px !important',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 18
    },
    scoreNumber: {
        marginLeft: "auto",
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: 40,
        fontWeight: 'bold'
    },
    leftPart: {
        marginLeft: 15
    },
    scoreTitle: {
        color: '#001942',
        fontWeight: 'bold',
        fontSize: 15,
        textAlign: 'center'
    },
    logo: {
        width: 200,
        bottom: 0,
        marginBottom: 20
    },
    logoContainer: {
        width: '100%',
        margin: 'auto',
        bottom: 0
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    '@media (max-height: 620px)': {
        nameLogoutContainer: {
            marginTop: 40
        }
    },
    '@media (max-height: 540px)': {
        nameLogoutContainer: {
            marginTop: 40
        }
    },
    absoluteposition: {
        position: 'absolute'
    },
    logoLoading: {
        width: 200,
        marginTop: 200
    },
    '@media (min-width: 800px)': {
        subContainer: {
            width: '70%'
        },
        logoContainer: {
            width: '70%'
        }
    },
    '@media (min-width: 1000px)': {
        subContainer: {
            width: '50%'
        },
        logoContainer: {
            width: '50%'
        }
    },
    '@media (min-width: 1300px)': {
        subContainer: {
            width: '30%'
        },
        logoContainer: {
            width: '30%'
        }
    },
}
