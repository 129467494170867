import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import style from './InformationsRse_styles';
import {Button} from "antd";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = createUseStyles(style);

const InformationsRse = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <div className={classes.headerContainer}>
                    <Link to={"/home"}>
                        <IconButton style={{
                            backgroundColor: '#99A0AB',
                            height: 35,
                            width: 35,
                            left: 15,
                            top: 15
                        }}>
                            <HomeIcon className={classes.backIcon}/>
                        </IconButton>
                    </Link>
                </div>
                <div className={classes.formContainer}>
                    <h1>Questionnaire d’auto-évaluation des pratiques RSE d’une organisation</h1>
                    <div style={{color: "black"}}>
                        {/*<span>Objectif : Mieux connaître vos forces et vos points d’amélioration sur vos pratiques RSE</span><br/>*/}
                        <span>A travers ce questionnaire, vous aurez en quelques minutes un état des lieux des pratiques RSE de votre organisation et pourrez ainsi mieux connaître vos forces et vos points d’amélioration.</span><br/><br/>
                        <span>A la clé, les avantages d’une organisation ayant une performance en matière de responsabilité sociétale se traduisent par :</span><br/><br/>
                        <ul className={classes.listInformations}>
                            <li className={classes.listItem}>La réduction de son impact environnemental et amélioration de son impact social</li>
                            <li className={classes.listItem}>Un fonctionnement de l’entreprise plus agile et plus résilient</li>
                            <li className={classes.listItem}>Un vecteur de performance financière et de productivité</li>
                            <li className={classes.listItem}>Une réduction de ses coûts</li>
                            <li className={classes.listItem}>Un outil de gestion des risques (financiers, fournisseurs, climatiques, image,…)</li>
                            <li className={classes.listItem}>Des avantages concurrentiels</li>
                            <li className={classes.listItem}>Une meilleure réputation</li>
                            <li className={classes.listItem}>Une capacité à attirer et à retenir ses salarié(e)s ou ses membres, ses clients ou ses utilisateurs</li>
                            <li className={classes.listItem}>Le maintien de la motivation et de l’engagement de ses employés, ainsi que de leur productivité</li>
                        </ul>
                        <span className={classes.RepInfoTitle} >Questionnaire avec réponse sur 4 niveaux :</span>
                        <ul className={classes.listInformations}>
                            <li className={classes.listItem}>Niveau 1 - Prise de conscience = Nous ne le faisons pas (encore)</li>
                            <li className={classes.listItem}>Niveau 2 - Initiation = Nous le faisons partiellement/ponctuellement</li>
                            <li className={classes.listItem}>Niveau 3 - Maitrise = Nous le faisons de façon structurée et suivie</li>
                            <li className={classes.listItem}>Niveau 4 - Exemplarité = La qualité de notre démarche est reconnue par nos clients</li>
                        </ul>
                    </div>
                    <Link to={'/addrse/1'}>
                        <Button className={classes.submitButton}>Commencer le questionnaire</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default InformationsRse;
