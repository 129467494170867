import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import {Link, useParams} from 'react-router-dom';
import {Redirect} from "react-router-dom"
import { withRouter } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import style from './AddRse_styles';
import IconButton from "@material-ui/core/IconButton";
import {Theaters} from "@material-ui/icons";
import ThemeQuestion from "../../components/themeQuestion/ThemeQuestion";
import {theme1, theme2, theme3, theme4, theme5} from '../../data/data';
import {Button} from "antd";
import {ADDTHEME1, ADDTHEME2, ADDTHEME3, ADDTHEME4, ADDTHEME5, USECRITERE} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {postRseResult} from "../../actions/rse";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = createUseStyles(style);

const AddRse = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    let {themeid} = useParams();
    const rse = useSelector(store => store.rse);
    const [ThemeTitle, setThemeTitle] = useState();
    const [ThemeData, setThemeData] = useState();

    const [redirectNext, setRedirectNext] = useState(0)
    const [redirectResult, setredirectResult] = useState(false)
    const [redirectBack, setredirectBack] = useState("");

    const [answer1, setanswer1] = useState();
    const [answer2, setanswer2] = useState();
    const [answer3, setanswer3] = useState();
    const [answer4, setanswer4] = useState();
    const [answer5, setanswer5] = useState();

    let stateArray = [];
    stateArray.push(setanswer1);
    stateArray.push(setanswer2);
    stateArray.push(setanswer3);
    stateArray.push(setanswer4);
    stateArray.push(setanswer5);

    let stateArrayAnswer = [];
    stateArrayAnswer.push(answer1);
    stateArrayAnswer.push(answer2);
    stateArrayAnswer.push(answer3);
    stateArrayAnswer.push(answer4);
    stateArrayAnswer.push(answer5);
    stateArrayAnswer.push(answer5);

    const getOldData = (dataString) => {
        if (dataString.length > 0) {
            setanswer1(parseInt(dataString[0]));
            setanswer2(parseInt(dataString[1]));
            setanswer3(parseInt(dataString[2]));
            setanswer4(parseInt(dataString[3]));
            setanswer5(parseInt(dataString[4]));
        }
    }

    useEffect(() => {
        switch (themeid) {
            case "1":
                setThemeData(theme1);
                setThemeTitle("Gouvernance");
                setredirectBack("/informationsRse");
                getOldData(rse.theme1);
                break;
            case "2":
                setThemeData(theme2);
                setThemeTitle("Droit de l’homme et conditions de travail");
                setredirectBack("/addrse/1");
                getOldData(rse.theme2);
                break;
            case "3":
                setThemeData(theme3);
                setThemeTitle("Environnement");
                setredirectBack("/addrse/2");
                getOldData(rse.theme3);
                break;
            case "4":
                setThemeData(theme4);
                setThemeTitle("Communauté et développement local");
                setredirectBack("/addrse/3");
                getOldData(rse.theme4);
                break;
            case "5":
                setThemeData(theme5);
                setThemeTitle("Loyauté des pratiques");
                setredirectBack("/addrse/4");
                getOldData(rse.theme5);
                break;
            default:
                setThemeTitle("Ce numéro de thème n'est pas disponible");
                break;
        }
    }, [props.match.params.themeid, rse]);

    const submitTheme = () => {
        if (!answer1 || !answer2 || !answer3 || !answer4 || !answer5) {
            alert("Veuillez répondre à toutes les questions");
        } else {
            const result = answer1.toString() + answer2.toString() + answer3.toString() + answer4.toString() + answer5.toString();
            let tmpRse = {...rse};
            switch (themeid) {
                case "1":
                    dispatch({
                        type: ADDTHEME1,
                        payload: result
                    });
                    tmpRse.theme1 = result;
                    postRseResult(tmpRse).then(res => {
                        setRedirectNext(2);
                    });
                    break;
                case "2":
                    dispatch({
                        type: ADDTHEME2,
                        payload: result
                    });
                    tmpRse.theme2 = result;
                    postRseResult(tmpRse).then(res => {
                        setRedirectNext(3);
                    });
                    break;
                case "3":
                    dispatch({
                        type: ADDTHEME3,
                        payload: result
                    });
                    tmpRse.theme3 = result;
                    postRseResult(tmpRse).then(res => {
                        setRedirectNext(4);
                    });
                    break;
                case "4":
                    dispatch({
                        type: ADDTHEME4,
                        payload: result
                    });
                    tmpRse.theme4 = result;
                    postRseResult(tmpRse).then(res => {
                        setRedirectNext(5);
                    });
                    break;
                case "5":
                    dispatch({
                        type: ADDTHEME5,
                        payload: result
                    });
                    tmpRse.theme5 = result;
                    postRseResult(tmpRse).then(res => {
                        setredirectResult(true)
                    });

                    break;
            }
            // submit answers
        }
    }

    if (redirectNext > 0) {
        return <Redirect to={{ ...props.location, pathname: `/addrse/${redirectNext}` }} />
    } else if (redirectResult) {
        return <Redirect to={'/resultrse'} />
    } else {
        return (
            <div className={classes.container}>
                <div className={classes.subContainer}>
                    <div className={classes.headerContainer}>
                        <Link to={redirectBack}>
                            <IconButton style={{
                                backgroundColor: '#99A0AB',
                                height: 35,
                                width: 35,
                                left: 15,
                                top: 15
                            }}>
                                <ArrowBackIosIcon className={classes.backIcon}/>
                            </IconButton>
                        </Link>
                        <Link to={"/home"}>
                            <IconButton style={{
                                backgroundColor: '#99A0AB',
                                height: 35,
                                width: 35,
                                left: -15,
                                top: 15
                            }}>
                                <HomeIcon style={{margin: 0}} className={classes.backIcon}/>
                            </IconButton>
                        </Link>
                    </div>
                    <div className={classes.formContainer}>
                        {/*<img alt={"label 1 étoile"} src={"import ici"} className={classes.logo}/>*/}
                        <div className={classes.subContainerQuestions}>
                            {ThemeTitle && <span className={classes.mainTitle}>{ThemeTitle}</span>}
                            {ThemeData && ThemeData.map((question, index) => (
                                <ThemeQuestion
                                    key={index}
                                    setanswer={(e) => stateArray[index](e)}
                                    answer={stateArrayAnswer[index]}
                                    number={(index + 1)}
                                    title={question[0]}
                                    description={question[1]}
                                />
                            ))}
                        </div>
                        <Button className={classes.submitButton} onClick={() => submitTheme()}>Suivant</Button>
                    </div>
                </div>
            </div>
        )
    }
};

export default withRouter(AddRse);
