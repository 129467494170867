export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        color: 'white',
        margin: 'auto',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        animation: '15s ease 0s infinite $backgroundAnime'
    },
    '@keyframes backgroundAnime': {
        '0%':{
            backgroundPosition: '0% 50%',
        },
        '50%':{
            backgroundPosition: '100% 50%',
        },
        '100%':{
            backgroundPosition: '0% 50%',
        }
    },
    backIcon: {
        color: 'white',
        marginLeft: 5
    },
    subContainer: {
        width: "100%",
        margin: 'auto'
    },
    formContainer: {
        backgroundColor: 'white',
        borderRadius: 15,
        marginTop: 70,
        marginBottom: 35,
        overflow: 'auto',
        marginRight: 10,
        marginLeft: 10,
        padding: 10
    },
    formLabelCustom: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        height: 32,
        color: "rgba(0, 0, 0, 0.85)",
        fontSize: 14,
        textAlign: 'left',
        '&:before': {
            display: "inline-block",
            marginRight: 4,
            color: "#ff4d4f",
            fontSize: 14,
            fontFamily: "SimSun, sans-serif",
            lineHeight: 1,
            content: "'*'"
        }
    },
    submitButton: {
        borderRadius: 10,
    },
    submitRight: {
        width: 'auto'
    },
    displaySteps: {
        marginTop: 18,
        marginRight: 20,
        fontSize: 19
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto',
        flexDirection: 'row',
        width: '60%'
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    QuestionTips: {
        fontStyle: 'italic',
        marginRight: 5
    },
    '@media (min-width: 800px)': {
        subContainer: {
            width: '70%'
        }
    },
    '@media (min-width: 1200px)': {
        subContainer: {
            width: '50%'
        }
    },
    '@media (max-width: 800px)': {
        buttonContainer: {
            width: '80%'
        }
    },
    '@media (max-width: 600px)': {
        buttonContainer: {
            width: '100%'
        }
    },
}