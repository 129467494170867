export default {
    container: {
        position: 'fixed',
        overflow: 'auto',
        padding: 0,
        color: 'white',
        margin: 'auto',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Open Sans',
        background: 'linear-gradient(to bottom left, #00050D, #001942)',
        backgroundSize: '400% 400%',
        animation: '15s ease 0s infinite $backgroundAnime'
    },
    '@keyframes backgroundAnime': {
        '0%':{
            backgroundPosition: '0% 50%',
        },
        '50%':{
            backgroundPosition: '100% 50%',
        },
        '100%':{
            backgroundPosition: '0% 50%',
        }
    },
    backIcon: {
        color: 'white'
    },
    subContainer: {
        width: "100%",
        margin: 'auto'
    },
    textClass: {
        color: 'black'
    },
    RepInfoTitle: {
        lineHeight: 3,
        fontSize: 15,
        fontWeight: 'bold'
    },
    listInformations: {
        listStyleType: 'space-counter',
        width: '70%',
        margin: 'auto'
    },
    submitButton: {
        marginTop: 20
    },
    listItem: {
        heigh: 'auto',
        textAlign: 'left',
        marginBottom: 5
    },
    formContainer: {
        backgroundColor: 'white',
        borderRadius: 15,
        marginTop: 70,
        marginBottom: 35,
        overflow: 'auto',
        marginRight: 10,
        marginLeft: 10,
        padding: 20
    },
    formLabelCustom: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        height: 32,
        color: "rgba(0, 0, 0, 0.85)",
        fontSize: 14,
        textAlign: 'left',
        '&:before': {
            display: "inline-block",
            marginRight: 4,
            color: "#ff4d4f",
            fontSize: 14,
            fontFamily: "SimSun, sans-serif",
            lineHeight: 1,
            content: "'*'"
        }
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    mainTitle: {
        fontWeight: 'bold',
        fontSize: 25,
        color: 'black',
        marginBottom: "25%"
    },
    '@media (min-width: 800px)': {
        subContainer: {
            width: '70%'
        }
    },
    '@media (min-width: 1200px)': {
        subContainer: {
            width: '50%'
        }
    },
    '@media (max-width: 800px)': {
        buttonContainer: {
            width: '80%'
        }
    },
    '@media (max-width: 600px)': {
        buttonContainer: {
            width: '100%'
        }
    },
}