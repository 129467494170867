import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {Input} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {auth, login} from "../../actions/auth";
import style from './login_styles';
import Button from "antd/es/button";
//import LogoProjexBlanc from "../../images/LOGO_GP_BLANC.png";
import LogoProjexBlanc from "../../images/LOGO_GLOBAL_PERF_BLANC.png";

const useStyles = createUseStyles(style);

const Login = () => {
    const classes = useStyles();
    const {isAuth} = useSelector(store => store.user);
    const dispatch = useDispatch();
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [RedirectRegister, setRedirectRegister] = useState(false);

    const validate = useCallback(() => {
        login(email, password)(dispatch)
            .catch(() => setpassword(""))
            .then(() => console.log)

    }, [email, password]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            validate();
        }
    }, [validate]);
    if (isAuth) {
        return <Redirect to={'/home'}/>
    }
    else if (RedirectRegister) {
        return (<Redirect to={'/register'} />)
    } else {
        return (
            <div className={classes.container}>
                <div className={classes.formContainer}>
                <span className={classes.loginTitle}>Se Connecter</span>
                    <div className={classes.loginForm}>
                        <Input
                            placeholder={"email"}
                            className={classes.formInput}
                            onChange={e => setemail(e.target.value.toLowerCase())}
                            value={email}
                        />
                        <Input
                            placeholder={"mot de passe"}
                            className={classes.formInput}
                            type={"password"}
                            value={password}
                            onChange={e => setpassword(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                </div>
                <div className={classes.tipsText}>Pour utiliserr l'application global performance, vous devez créer un
                    compte gratuitement.
                </div>
                <div className={classes.columnWrapper}>
                    <Button onClick={validate} className={classes.button}>
                        CONNEXION
                    </Button>
                    <div className={classes.orTitle} >OU</div>
                    {/*<Link to={"/register"}>*/}
                    <Button onClick={() => setRedirectRegister(true)} className={classes.button}>
                        Créer un compte
                    </Button>
                    {/*<p className={classes.button}>Créer un compte</p>*/}
                    {/*</Link>*/}
                </div>
                <Link to={"/forgotPassword"}>
                    <p className={classes.SubText}>Mot de passe
                        oublié</p>
                </Link>
                <img src={LogoProjexBlanc} className={classes.logo}/>
            </div>
        )
    }
};

export default Login;
