import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import style from './resetPassword_style';
import {Link, useParams} from 'react-router-dom';
import {Input} from "antd";
import Button from "antd/es/button";
import LogoProjexBlanc from "../../images/LOGO_GP_BLANC.png";
import { resetPassword } from "../../actions/auth";
import {useDispatch} from "react-redux";

const useStyles = createUseStyles(style);

const ResetPassword = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [firstpassword, setfirstpassword] = useState("");
    const [secondpassword, setsecondpassword] = useState("");
    const { token } = useParams();

    const validate = useCallback(() => {
        if (firstpassword === secondpassword) {
            resetPassword(firstpassword, token)(dispatch).catch(() => {
                setfirstpassword("");
                setsecondpassword("");
            }).then(() => console.log());
        }
        /*login(email, password)(dispatch)
            .catch(()=> setpassword(""))
            .then(() => console.log)*/

    }, [firstpassword, secondpassword]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            validate();
        }
    },[validate]);

    return (
        <div className={classes.container}>
            <div className={classes.formContainer}>
                <span className={classes.loginTitle}>Réinitialiser son mot de passe</span>
                <Input
                    placeholder={"nouveau mot de passe"}
                    className={classes.formInput}
                    type={"password"}
                    onChange={e => setfirstpassword(e.target.value)}
                    value={firstpassword}
                />
                <Input
                    placeholder={"confirmez votre mot de passe"}
                    className={classes.formInput}
                    type={"password"}
                    value={secondpassword}
                    onChange={e => setsecondpassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </div>
            <Button onClick={validate} className={classes.button}>
                Valider mon nouveau mot de passe
            </Button>
            <Link to={"/login"}>
                <p className={classes.SubText}>Se connecter</p>
            </Link>
            <img src={LogoProjexBlanc} className={classes.logo}/>
        </div>
    )
};

export default ResetPassword;
