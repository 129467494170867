export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";

export const USEFOLDER = "USEFOLDER";
export const USECRITERE = "USECRITERE";
export const RESETFOLDER = "RESETFOLDER";

export const ADDTHEME1 = "ADDTHEME1";
export const ADDTHEME2 = "ADDTHEME2";
export const ADDTHEME3 = "ADDTHEME3";
export const ADDTHEME4 = "ADDTHEME4";
export const ADDTHEME5 = "ADDTHEME5";
export const RESETRSE = "RESETRSE";
export const FILLSTATE = "FILLSTATE";