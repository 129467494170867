import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import style from './profile_styles';

const useStyles = createUseStyles(style);

const Profile = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            Profile
        </div>
    )
};

export default Profile;