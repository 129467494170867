import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import './rotateLoading.css';
import style from './loading_style';
import LogoGlobalPerfBlanc from "../../images/loader_small.gif";

const useStyles = createUseStyles(style);

const Loading = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <img className={classes.loader} src={LogoGlobalPerfBlanc}/>
            </div>
        </div>
    );
};

export default Loading;